import React from "react";
import images from "assets/images";
import { Dropdown, ButtonGroup } from "react-bootstrap";

const NavDropMenu: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <Dropdown className="dropdown-nav" as={ButtonGroup}>
      <Dropdown.Toggle className="font bold nav-link">
        {title}
        <img
          className="orange-arrow"
          src={images.orangetrin}
          width="15"
          height="15"
          alt=""
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default NavDropMenu;
