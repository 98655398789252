import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import LoanRequestModal from "./components/LoanRequestModal";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { loanRequestContent } from "content/loan_request";
import LoanRequestCarousel from "./components/LoanRequestCarousel";
import LoanRequestForm from "./components/LoanRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { GeneralLoanType } from "types/backend.modal";
import { RootState } from "store/configStore";
import { useLocation, useNavigate } from "react-router-dom";
import images from "assets/images";
import { Helmet } from "react-helmet";

interface Props {
  type: GeneralLoanType;
  amountOfLoans: any[];
  API_getAmountsActionThunk: any;
  API_postFormActionThunk: any;
}

const LoanRequest: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const loc = useLocation();
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const [success, setSuccess] = useState({ sent: false, error: false });
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setSuccess({ sent: false, error: false });
  }, [loc.pathname]);

  return (
    <>
      <Helmet>
      <title>{"Request Loan " + props.type + " - amanmicrofinance.com"}</title>
      </Helmet>
      

      <DefaultMain>
        <section className="loan-request-section">
          <LoanRequestModal type={props.type} />
          <Row>
            <Container className="loan-request-carousel-container" fluid>
              <h1 className="font bold carousel-header">
                {t(loanRequestContent.carouselHeader)}
              </h1>
              <LoanRequestCarousel />
            </Container>
            <Col className="loan-request-form-container">
              {!success.sent ? (
                <LoanRequestForm
                  setSuccess={setSuccess}
                  type={props.type}
                  API_postFormActionThunk={props.API_postFormActionThunk}
                  amountOfLoans={props.amountOfLoans}
                />
              ) : (
                <div className="success-form">
                  <div className="succes-form-icon-text">
                    <img
                      src={success.error ? images.errorSVG : images.successSVG}
                    />
                    <h3>
                      {success.error
                        ? t("An Error Occured Please try again")
                        : "تم تسجيل طلبك بنجاح"}
                    </h3>
                    {!success.error && (
                      <h3>عميلنا العزيز سيتم التواصل معك في أقرب وقت</h3>
                    )}{" "}
                  </div>
                  <button
                    onClick={() => {
                      if (success.error) {
                        setSuccess({ sent: false, error: false });
                      } else {
                        navigate("/");
                      }
                    }}
                    className="send-form-button"
                  >
                    {success.error ? "أعد المحاولة" : "الذهاب للصفحة الرئيسية"}
                  </button>
                  {!success.error && (
                    <>
                      <h3>شكرا لثقتك بامان</h3>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </section>
      </DefaultMain>
    </>
  );
};
export default LoanRequest;
