import React from "react";
import { Image } from "react-bootstrap";
import images from "../../../assets/images";

const CircleIcon: React.FC<{ icon: any; className?: string }> = ({
  icon,
  children,
  className,
}) => {
  const content =
    icon === "" ? (
      children
    ) : (
      <Image
        className="icon-img"
        width={130}
        height={130}
        alt="50x50"
        src={icon}
      />
    );

  return (
    <div className={`circle-icon-container ${className ? className : ""}`}>
      <div
        data-aos="zoom-in"
        data-aos-duration="400"
        className="circle-icon aos-init"
      >
        <Image
          className="kite-img"
          src={images.kiteCircle}
          width={240}
          height={227}
          alt="110*110"
        />

        {content}
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="400"
        className="icon-text aos-init"
      >
        {icon !== "" && children}
      </div>
    </div>
  );
};
export default CircleIcon;
