import React, { useEffect, useRef, useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { loanRequestContent, loanRequestFormSteps } from "content/loan_request";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { Branch, Government } from "types";
import {
  GeneralLoanType,
  LoanReqData,
  LoanReqMFData,
} from "types/backend.modal";
import CommonOrangeButton from "components/Button";
import { API_getPhoneNumerLoanStatus } from "store/actions/loanReqActions";
import images from "assets/images";
import CommonModal from "components/CommonModal";
import axios from "axios";
import { isTemplateExpression } from "typescript";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import "../../../style/components/_loan-request-step-two.scss";
import FormBaseButton from "./FormBaseButton";
import ReactLoading from "react-loading";
import { useSearchParams } from "react-router-dom";
import useDebounce from "helper/useDebounce";
import FormModal from "./FormModal";
import DropdownSelect from "./DropDownSelect";
import LocationDropdownSelect from "./LocationDropdownSelect";
import BranchesDropDownSelect from "./BranchesDropDownSelect";
interface Props {
  type: GeneralLoanType;
  amountOfLoans: any[];
  API_postFormActionThunk: (
    data: LoanReqMFData
  ) => (dispatch: React.Dispatch<any>) => Promise<any>;
  setSuccess: Function;
  setIsSubModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSubModalContent: React.Dispatch<
    React.SetStateAction<"success" | "sorry" | "error" | "terms" | null>
  >;
  API_ValidatePromoCodeThunk: (
    code: string
  ) => (dispatch: React.Dispatch<any>) => Promise<any>;
  selectedFrontImage: string | null;
  selectedBackImage: string | null;
  nationalID: string | null;
  fullName: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
}
type staticOptionsObject = {
  id: number;
  description: string;
  value: string;
};
type TActivity = {
  productId: number;
  productName: string;
  minimumAmount: number;
  maximumAmount: number;
};

const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const StepTwo: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vodafoneRedirected = searchParams.get("vodafoneRedirected") === "true";
  const hasPromoCode = searchParams.get("hasPromoCode") === "true";

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const [isFullNameValid, setIsFullNameValid] = useState<boolean | undefined>(
    undefined
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [displayedGovernments, setDisplayedGovernments] = useState<
    Government[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayedBranches, setDisplayedBranches] = useState<Branch[]>([]);
  const [isSentSucc, setIsSentSucc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckBtnShown, setIsCheckBtnShown] = useState(true);
  const [selectedGovernmentId, setSelectedGovernmentId] = useState("0");
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedAmountOfLoanId, setSelectedAmountOfLoanId] = useState("0");
  const [selectedProjectType, setSelectedProjectType] = useState<
    number | string
  >("");
  const [selectedProjectTypeID, setSelectedProjectTypeID] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [hasWorkingProject, setHasWorkingProject] = useState(false);
  const [hasOfficialDocuments, setHasOfficialDocuments] = useState(false);
  const [salesOverOneMillion, setSalesOverOneMillion] = useState(false);
  const [isSendingPhoneNumber, setIsSendingPhoneNumber] = useState(false);
  const [promoCode, setPromoCode] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isSubModalVisible, setIsSubModalVisible] = useState<boolean>(false);
  const [isPhoneNumberEgyption, setIsPhoneNumberEgyption] = useState<
    boolean | undefined
  >(undefined);
  const [isSubmitShown, setIsSubmitShown] = useState(false);
  const [isNumberAlreadyApplied, setIsNumberAlreadyApplied] = useState(false);
  const [typesOfMontag, setTypesOfMontag] = useState([]);
  const [govermentsList, setGovermentsList] = useState([]);
  const [govermentName, setGovermentName] = useState("");
  const [branchesList, setBranchesList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [city, setCity] = useState("-1");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [transportProduct, setTransportProduct] = useState({
    id: "-1",
    name: "",
  });
  const [selectedReceiptImage, setSelectedReceiptImage] = useState<
    string | null
  >(null);
  const [selectedActivityImage, setSelectedActivityImage] = useState<
    string | null
  >(null);
  const [isHovered, setIsHovered] = useState(false);
  // STATIC DATA
  const projectTypes: staticOptionsObject[] = [
    { id: 1, description: t("Commercial"), value: "commercial" },
    { id: 2, description: t("Industrial"), value: "industrial" },
    { id: 3, description: t("Service"), value: "service" },
    { id: 4, description: t("Agricultural"), value: "agricultural" },
  ];

  const transportationProductType = [
    {
      id: 1,
      name: "موتورسيكل",
    },
    {
      id: 2,
      name: "تروسيكل",
    },
  ];

  const clearForm = () => {
    props.setFullName("");
    setPhoneNumber("");
    setSelectedGovernmentId("0");
    setSelectedBranchId("0");
    setSelectedAmountOfLoanId("0");
    setDisplayedBranches([]);
    setIsCheckBtnShown(true);
    setIsSubmitShown(false);
  };

  const validatePromoCode = async (val: string) => {
    if (val !== "") {
      const resMessage: any = await dispatch(
        props.API_ValidatePromoCodeThunk(val)
      );
      console.log("resMessage.status : ", resMessage);
      if (resMessage.status == 200 || resMessage.status == 201) {
        setIsValid(true);
        setIsSubModalVisible(true);
      } else {
        setIsValid(false);
        setIsSubModalVisible(true);
      }
      // clearForm();
      return resMessage;
    }
  };
  const sendLoanMFReq = async (e: any) => {
    e.preventDefault();
    setIsSending(true);
    const resMessage: any = await dispatch(
      props.API_postFormActionThunk({
        amount_of_loan: selectedAmountOfLoanId,
        product_id: selectedProjectTypeID,
        full_name: props.fullName,
        government: Number(city),
        branch: Number(selectedBranchId),
        phone_number: phoneNumber,
        activity_type: selectedProjectTypeID,
        government_name: govermentName,
        have_existing_activity: hasWorkingProject,
        id_card_back: props.selectedBackImage,
        id_card_front: props.selectedFrontImage,
        national_id: props.nationalID,
        promo_id: 1,
        voucher_code: promoCode,
        utility_bills: selectedReceiptImage,
        term_period: "1",
        picture_for_business: selectedActivityImage ?? null,
      })
    );
    if (resMessage.status == 200 || resMessage.status == 201) {
    } else {
    }
    console.log("sendLoanReq : ", resMessage);
    setIsSending(false);

    // clearForm();
    return resMessage;
  };
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resMessage = await sendLoanMFReq(event);
    if (resMessage.status == 200 || resMessage.status == 201) {
      props.setIsSubModalVisible(true);
      props.setSubModalContent("success");
    } else {
      // console.log();

      props.setIsSubModalVisible(true);
      props.setSubModalContent("error");
    }
  };
  const checkPhoneNumber = async (e: any) => {
    e.preventDefault();
    setIsSendingPhoneNumber(true);
    const isUserNumberAvaliable = (await dispatch(
      API_getPhoneNumerLoanStatus(phoneNumber, props.type)
    )) as unknown as boolean;
    setIsSendingPhoneNumber(false);
    if (isUserNumberAvaliable) {
      setIsCheckBtnShown(false);
      setIsSubmitShown(true);
      setIsNumberAlreadyApplied(false);
    } else {
      setIsNumberAlreadyApplied(true);
    }
  };
  const isSendDisabled = () => {
    if (props.type === "mf") {
      return (
        !isFullNameValid ||
        props.fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        !hasWorkingProject ||
        selectedAmountOfLoanId == "0" ||
        selectedBranchId == "0"
      );
    } else if (props.type === "sme") {
      return (
        !isFullNameValid ||
        props.fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        !salesOverOneMillion ||
        !hasOfficialDocuments ||
        selectedAmountOfLoanId == "0" ||
        selectedBranchId == "0"
      );
    } else if (props.type === "lv") {
      return (
        !isFullNameValid ||
        props.fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        selectedAmountOfLoanId == "0" ||
        selectedBranchId == "0" ||
        transportProduct.id == "-1"
      );
    }
  };

  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/content/amount-of-loans?activityType=${
          props.type == "lv" ? "mf" : props.type
        }`
      )
      .then((res) => {
        setTypesOfMontag(res.data.Data);
        console.log("GET ACTIVITY TYPE:", res);
      })
      .catch(() => {});

    axios
      .get(`https://mf.amanmicrofinance.com/api/company/governemnts-v2/1`)
      .then((res) => {
        setGovermentsList(res.data.data);
        console.log("GET GOVERNMENT CATEGORIES SUCECESS: ", res);
      })
      .catch((err) => {
        console.log("GET GOVERNMENT CATEGORIES FAILED: ", err);
      });
  }, [props.type]);
  useEffect(() => {
    axios
      .get(`https://mf.amanmicrofinance.com/api/company/get-products-v2/1`)
      .then((res) => {
        setActivityList(res.data.data);
        console.log("GET Products CATEGORIES SUCECESS: ", res);
      })
      .catch((err) => {
        console.log("GET GOVERNMENT CATEGORIES FAILED: ", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/company/branches-v2/${city}?activity_id=1`
      )
      .then((res) => {
        setBranchesList(res.data.data);
        console.log("GET BRANCHES SUCCESS: ", res);
      })
      .catch((err) => {
        console.log("GET BRANCHES FAILED: ", err);
      });
  }, [city]);

  useEffect(() => {
    if (props.fullName.length > 0)
      setIsFullNameValid(
        (/^[\u0621-\u064A| +]+$/.test(props.fullName.trim()) ||
          /^[a-zA-Z| +]+$/.test(props.fullName.trim())) &&
          props.fullName.trim().length > 10
      );
    else setIsFullNameValid(undefined);
  }, [props.fullName]);

  /*   useEffect(() => {
    console.log("full_name_ocr", full_name_ocr)
  }, [full_name_ocr]) */
  const validateForm = () => {
    const isAmountValid =
      selectedAmountOfLoanId !== "0" &&
      Number(selectedAmountOfLoanId) >= 10000 &&
      Number(selectedAmountOfLoanId) <= 242000;
    const isFullNameValid = props.fullName && props.fullName.trim().length > 0;
    const isPhoneNumberValid =
      phoneNumber && phoneNumber.length === 11 && phoneNumber.startsWith("0");
    const isProjectTypeValid =
      props.type !== "mf" ||
      (selectedProjectType && selectedProjectType !== "");
    const isCityValid = city && city !== "-1";
    const isBranchValid = selectedBranchId && selectedBranchId !== "0";
    const isReceiptImageValid = !!selectedReceiptImage;

    // All required fields must be valid
    const isValid =
      isAmountValid &&
      isFullNameValid &&
      isPhoneNumberValid &&
      isProjectTypeValid &&
      isCityValid &&
      isBranchValid &&
      hasWorkingProject &&
      isReceiptImageValid;

    setIsSubmitDisabled(!isValid);
  };
  // useEffect(() => {
  //   if (governments.length !== 0) {
  //     setDisplayedGovernments([
  //       {
  //         id: 0,
  //         government: t("Choose a Governorate.."),
  //       },
  //       ...governments,
  //     ]);
  //   }
  // }, [governments]);

  // useEffect(() => {
  //   if (selectedGovernmentId != "0") {
  //     const selectedGovernemateObject = governments.filter(
  //       (government: Government) =>
  //         government.id == Number(selectedGovernmentId)
  //     )[0];
  //     setDisplayedBranches([
  //       {
  //         id: 0,
  //         name: t("Choose Branch.."),
  //       },
  //       ...selectedGovernemateObject.branches,
  //     ]);
  //   } else {
  //     setDisplayedBranches([]);
  //   }
  //   setSelectedBranchId("0");
  // }, [selectedGovernmentId]);

  useEffect(() => {
    if (phoneNumber.length > 0 && phoneNumber.length < 12)
      setIsPhoneNumberEgyption(EgyptionNumbersRegex.test(phoneNumber));
    else {
      setIsPhoneNumberEgyption(undefined);
    }

    //console.log("phoneNumber.length", phoneNumber.length)
    setIsNumberAlreadyApplied(false);
    setIsCheckBtnShown((val: boolean) => {
      if (!val) return true;
      else return val;
    });
    setIsSubmitShown((val: boolean) => {
      if (val) return false;
      else return val;
    });
  }, [phoneNumber]);
  useEffect(() => {
    if (props.amountOfLoans.length !== 0) {
      // setSelectedAmountOfLoanId(props.amountOfLoans[0].id);
    }
  }, [props.amountOfLoans]);
  useDebounce(() => validatePromoCode(promoCode ?? ""), [promoCode], 1000);
  useEffect(() => {
    validateForm();
  }, [
    selectedAmountOfLoanId,
    props.fullName,
    phoneNumber,
    selectedProjectType,
    city,
    selectedBranchId,
    selectedReceiptImage,
    hasWorkingProject,
  ]);
  return (
    <>
      <CommonModal
        isSuccess={isSentSucc}
        successText="Your Message Has been sent successfully"
        errorText="An Error Occured Please try again"
        show={isModalOpen}
        handleClose={setIsModalOpen}
      />
      <Form
        className="loan-request-form"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <h1 className="font bold carousel-header">
          فاضل ع
          <span className="loan-request-form-container-inner-hours">
            {" "}
            التمويل{" "}
          </span>
          تكه
        </h1>

        <div
          className="navigation-sign"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="from">
            <p>1</p>
            <img src={images.completedStep} />
            <p>{t(loanRequestFormSteps.stepOneDescription)}</p>
          </div>
          <img src={images.linearArrow} width={"35%"} height={"auto"} />
          <div className="to">
            <p>2</p>
            <img src={images.step_two_orange_icon} />
            <p>{t(loanRequestFormSteps.stepTwoDescription)}</p>
          </div>
        </div>

        <Form.Group className="mb-3 my-col row-container ">
          <Form.Label className="font bold">
            {t("Amount of loan")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <div className="input-row">
            <img src={images.icon_money} className="start-icon" />
            <Form.Control
              value={selectedAmountOfLoanId > "0" ? selectedAmountOfLoanId : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                setSelectedAmountOfLoanId(value); // Update the value only if valid
              }}
              className={`${
                selectedAmountOfLoanId !== "0" &&
                Number(selectedAmountOfLoanId) >= 10000 &&
                Number(selectedAmountOfLoanId) <= 242000
                  ? ""
                  : "required-border"
              }`}
              type="text"
              style={{ paddingRight: "3rem" }}
            />
          </div>

          <div className="error-state mb-3" style={{ marginTop: "10px" }}>
            <img src={images.icon_warning} />
            <span className="text-optional">
              {" "}
              اقل مبلغ 10,000 و اكثر مبلغ 242,000
            </span>
          </div>
        </Form.Group>

        <Form.Group className="mb-3 row-container">
          <Form.Label className="font bold">
            {t("Full name")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <div className="input-row">
            <img src={images.icon_identification_card} className="start-icon" />
            <Form.Control
              className={`${
                isFullNameValid === false ? "required-border" : ""
              }`}
              maxLength={250}
              value={props.fullName}
              disabled={true}
              onChange={(e) => {
                props.setFullName(e.target.value);
              }}
              type="text"
              style={{ paddingRight: "3rem" }}
            />
          </div>
        </Form.Group>
        {isFullNameValid === false && (
          <div className="error-state mb-3">
            <img src={images.errorIcon} />
            <p>{t("Please enter valid full name")}</p>
          </div>
        )}

        <Form.Group className="mb-3 row-container">
          <Form.Label className="font bold">
            رقم الموبايل
            <span className="text-danger"> * </span>
          </Form.Label>
          <div className="input-row">
            <img src={images.icon_mobile} className="start-icon" />
            <Form.Control
              className="text-start"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              type="tel"
              inputMode="numeric"
              style={{ paddingRight: "3rem" }}
            />
            {(isPhoneNumberEgyption == undefined ||
              isPhoneNumberEgyption == false) && (
              <div className="error-state" style={{ width: "100%" }}>
                {/* <img src={images.errorIcon} /> */}
                {phoneNumber.startsWith("0") ? (
                  <span className="text-optional">
                    يجب أن يكون رقم الهاتف 11 رقمًا باللغة الإنجليزية
                  </span>
                ) : (
                  <span className="text-optional">
                    يجب أن يبدا رقم الهاتف بالرقم 0
                  </span>
                )}
              </div>
            )}
          </div>
        </Form.Group>

        {/* {props.type === "mf" && (
          <Form.Group className="mb-3 my-col row-container">
            <Form.Label className="font bold">
              {t("ActivityType")}
              <span className="text-danger"> * </span>
            </Form.Label>
            <div className="input-row">
              <img src={images.icon_activity_type} className="start-icon" />
              <Form.Select
                value={selectedProjectType}
                onChange={(e: any) => {
                  const selectedValue = parseInt(e.target.value, 10);
                  const selectedOption = activityList.find(
                    (option: TActivity) => option.productId === selectedValue
                  );
                  if (selectedOption) {
                    console.log("selected activity type", selectedOption);

                    setSelectedProjectTypeID(
                      (selectedOption as TActivity).productId
                    ); // Update the state with the ID
                  }
                  console.log("e.target.value", e.target.value);
                  setSelectedProjectType(e.target.value);
                }}
                style={{ paddingRight: "3rem" }}
              >
                <option>اختر نوع النشاط</option>
                {activityList.map((option: TActivity) => (
                  <option key={option.productId} value={option.productId}>
                    {option.productName}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
        )} */}
        <DropdownSelect
          onChange={(e: any) => {
            const selectedValue = e;
            const selectedOption = activityList.find(
              (option: TActivity) => option.productId === selectedValue
            );
            if (selectedOption) {
              console.log("selected activity type", selectedOption);
              setSelectedProjectTypeID((selectedOption as TActivity).productId); // Update the state with the ID
              setSelectedProjectType((selectedOption as TActivity).productId);
            }
          }}
          options={activityList}
          selectedValue={selectedProjectType}
          title={t("ActivityType")}
        />
        <Form.Group className="mb-3 row-container" style={{ rowGap: "1rem" }}>
          <Form.Label className="font bold">
            عنوان النشاط
            <span className="text-danger"> * </span>
          </Form.Label>
          {/* <div className="input-row">
            <img src={images.icon_map_pin} className="start-icon" />
            <Form.Select
              value={city}
              onChange={(e: any) => {
                const selectedId = parseInt(e.target.value, 10); // Convert value to number
                setCity(e.target.value); // Update the selected city ID

                // Find the governmentName by its ID
                const selectedGovernment = govermentsList.find(
                  (op) => (op as any).governmentId === selectedId
                );

                if (selectedGovernment) {
                  console.log(selectedGovernment);
                  setGovermentName((selectedGovernment as any).governmentName); // Update the state with the name
                }
              }}
              style={{ paddingRight: "3rem" }}
            >
              <option value={"-1"}>{"إختر المحافظة"} </option>
              {govermentsList.map((op: any) => (
                <option key={op.governmentId} value={op.governmentId}>
                  {op.governmentName}
                </option>
              ))}
            </Form.Select>
          </div> */}
          <LocationDropdownSelect
            onChange={(e: any) => {
              const selectedId = e; // Convert value to number
              setCity(e); // Update the selected city ID

              // Find the governmentName by its ID
              const selectedGovernment = govermentsList.find(
                (op) => (op as any).governmentId === selectedId
              );

              if (selectedGovernment) {
                console.log(selectedGovernment);
                setGovermentName((selectedGovernment as any).governmentName); // Update the state with the name
              }
            }}
            options={govermentsList}
            selectedValue={city}
            placeholder="اختر المحافظة"
            title=""
          />

          {/* <div className="input-row">
            <img src={images.icon_map_pin} className="start-icon" />
            <Form.Select
              value={selectedBranchId}
              onChange={(e: any) => {
                console.log("SELECTED BRANCH ID", e.target.value);
                setSelectedBranchId(e.target.value);
              }}
              disabled={city === "-1"}
              style={{ paddingRight: "3rem" }}
            >
              <option> اختر الفرع</option>

              {branchesList.map((op: any) => (
                <option key={op.branchId} value={op.branchId}>
                  {op.branchName}
                </option>
              ))}
            </Form.Select>
          </div> */}
          <BranchesDropDownSelect
            onChange={(e: any) => {
              setSelectedBranchId(e);
            }}
            disabled={city === "-1"}
            options={branchesList}
            selectedValue={selectedBranchId}
            placeholder="اختر المنطقة"
            title=""
          />
        </Form.Group>

        <Form.Group
          className="mb-3 row-container"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Label className="font bold" style={{ maxWidth: "50%" }}>
            صور ايصال مرافق للمشروع(كهرباء , مياه ){" "}
            <span className="text-danger"> * </span>
          </Form.Label>

          <FormBaseButton
            title={selectedReceiptImage ? "تغيير الصورة" : "رفع صورة"}
            onClick={() => document.getElementById("receiptImage")?.click()}
            icon={selectedReceiptImage ? "edit" : "upload"}
            backgroundColor={selectedReceiptImage ? "#E9581E" : "#02A9BF"}
            textColor="#FFFFFF"
          />
          <input
            type="file"
            id="receiptImage"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files?.[0];
              if (file) {
                const reader = new FileReader();

                reader.onload = () => {
                  const result = reader.result as string;
                  const base64String = result.replace(
                    /^data:image\/\w+;base64,/,
                    ""
                  );
                  setSelectedReceiptImage(base64String);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </Form.Group>
        <Form.Group
          className="mb-3 row-container"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Label className="font bold" style={{ maxWidth: "50%" }}>
            رفع صورة النشاط
            <span className="text-optional"> (اختياري) </span>
          </Form.Label>

          <FormBaseButton
            title={selectedActivityImage ? "تغيير الصورة" : "رفع صورة"}
            onClick={() => {
              document.getElementById("activityImage")?.click();
            }}
            icon={selectedActivityImage ? "edit" : "upload"}
            backgroundColor={selectedActivityImage ? "#E9581E" : "#02A9BF"}
            textColor="#FFFFFF"
          />

          <input
            type="file"
            id="activityImage"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files?.[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                  const result = reader.result as string;
                  const base64String = result.replace(
                    /^data:image\/\w+;base64,/,
                    ""
                  );
                  console.log("activty base 64", base64String);
                  setSelectedActivityImage(base64String);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </Form.Group>

        <>
          {props.type === "mf" && (
            <>
              <Form.Group className="mb-3 text-aligned-rtl">
                <Form.Label className="font bold">
                  {t(
                    "Do you have an existing business that has been in existence for at least a year?"
                  )}
                  <span className="text-danger"> * </span>
                </Form.Label>
                <Form.Check
                  checked={hasWorkingProject}
                  onChange={() => {
                    setHasWorkingProject(true);
                  }}
                  inline
                  label={t("Yes")}
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-1`}
                />
                <Form.Check
                  checked={!hasWorkingProject}
                  onChange={() => {
                    setHasWorkingProject(false);
                  }}
                  inline
                  label={t("No")}
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-2`}
                />
              </Form.Group>
              <p className="font light text-muted mb-2">
                {t(
                  "Existing income-generating projects are financed and have been established for at least one year"
                )}
              </p>
            </>
          )}

          {/* <CommonOrangeButton
            isSending={isSending}
            disabled={isSendDisabled()}
            onClick={sendLoanReq}
          >
            {t(loanRequestContent.formSubmitBtn)}
          </CommonOrangeButton> */}
          <>
            {hasPromoCode && (
              <Form.Group className="mb-3 row-container">
                <Form.Label className="font bold">
                  برومو كود
                  <span className="text-optional"> (اختياري) </span>
                </Form.Label>
                <div className="input-row">
                  <img src={images.icon_mobile} className="start-icon" />
                  <Form.Control
                    className="text-start"
                    maxLength={11}
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value.trim() as string);
                    }}
                    type="text"
                    style={{ paddingRight: "3rem" }}
                  />
                  {promoCode !== "" && (
                    <img
                      src={isValid ? images.icon_true : images.icon_false}
                      className="validate-icon"
                    />
                  )}
                </div>
              </Form.Group>
            )}
          </>
          <div className="form-modal-footer">
            <button
              className="form-modal-close-button"
              onClick={(event) => onSubmit(event)}
              disabled={isSubmitDisabled}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isSending ? (
                <ReactLoading
                  className="loading-element"
                  type="balls"
                  color={isHovered ? "#02A9BF" : "#fff"}
                  height={20}
                  width={25}
                />
              ) : (
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    fontFamily: "Tajawal",
                  }}
                >
                  ارسال الطلب
                </p>
              )}
            </button>
          </div>
        </>
        {isSubModalVisible && (
          <FormModal
            submitTitle={"حسنا"}
            onClose={() => {
              setIsSubModalVisible(false);
            }}
            icon={"close"}
            isDisabled={false}
            containerStyle={{ width: "auto", height: "auto", paddingBottom: 0 }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
                padding: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <img
                  src={images.VodafoneLogo}
                  width="auto"
                  height="auto"
                  alt=""
                />
                <h3>برومو-كود</h3>
              </div>
              <div>
                <img src={isValid ? images.icon_true : images.icon_false} />
              </div>
              <div>
                <h2>{isValid ? "تم تفعيل البرومو-كود" : "برومو-كود خاطئ"}</h2>
              </div>
            </div>
          </FormModal>
        )}
        {/* )} */}
      </Form>
    </>
  );
};

export default StepTwo;
