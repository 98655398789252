import React from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";

import images from "../../../assets/images";
import PayInstallment from "../components/PayInstallment";
import NoInstallments from "../components/NoInstallments";
import InstallmentPaid from "../components/InstallmentPaid";
import InstallmentsStart from "../components/InstallmentsStart";
import InstallmentsVerify from "../components/InstallmentsVerify";

import { useTranslation } from "react-i18next";
const Section1: React.FC = (props) => {
  const { t } = useTranslation();
  const { step } = useParams();

  let activeContent = <InstallmentsStart />;

  switch (step) {
    case "verify":
      activeContent = <InstallmentsVerify />;
      break;
    case "pay":
      activeContent = <PayInstallment />;
      break;
    case "noInstallments":
      activeContent = <NoInstallments />;
      break;
    case "installmentPaid":
      activeContent = <InstallmentPaid />;
      break;
    default:
      activeContent = <InstallmentsStart />;
      break;
  }

  return (
    <section className="installments-section">
      <div className="installments-box">
        <div className="box-content">
          <div className="content-header">
            <h4 className="font bold">{t("Aman Finance")}</h4>
            <h5 className="font bold">{t("Installment Payment")}</h5>
          </div>
          <div className="content-main">{activeContent}</div>
        </div>
        <div className="box-img">
          <Image src={images.installmentsCover} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Section1;
