import React, { useEffect } from "react";
import DefaultMain from "layout";
import {
  servicesItems,
  sec4Content,
  circlesRow,
} from "content/services";
import { Col, Container, Row } from "react-bootstrap";
import SectionHeader from "./components/SectionHeader";
import EmptyRow from "./components/EmptyRow";
import SectionRow from "./sections/SectionRow";
import CircleTextRow from "./sections/CircleTextRow";
import ReaderRow from "./sections/ReaderRow";
import SingleRow from "./sections/SingleRow";
import CircleIcon from "./components/CircleIcon";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";


const Services: React.FC = () => {
  const { t } = useTranslation();
  const { constants } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const elementInView = (el: any, scrollOffset = 150) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) -
        scrollOffset
    );
  };
  const sec3Content = {
    title: "Funding value",
    details: "Financing is designed to suit your activity, no matter how small",
    itemsList: [
      {
        txt: `${t('The value of the financing ranges from')} ${constants?.min_loan_amount} ${t('EGP up to 200,000 EGP, to be granted gradually')}`,
        img: images.circleIcon04,
        circleContent: [constants?.min_loan_amount, "Egyptian Pound"],
      },
      {
        txt: "",
        img: "",
        circleContent: ["200,000", "Egyptian Pound"],
      },
    ],
    itemsClasses: ["s45", "s21"],
  };

  useEffect(() => {
    const test = Array.from(document.querySelectorAll(".aos-init"));
    document.addEventListener("scroll", (e) => {
      for (const el of test) {
        if (elementInView(el)) {
          el.classList.add("aos-animate");
        } else {
          el.classList.remove("aos-animate");
        }
      }
    });
  });

  return (
    <>
      <Helmet>
        <title>Services - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        <section>
          <Container className="services-container" fluid>
            <SectionHeader />

            <EmptyRow className="left-space seprator-line" />
            <SectionRow className="h200" data={servicesItems[0]} />
            <EmptyRow className="left-space seprator-line" />
            <SectionRow className="h60 circles-2nd" data={servicesItems[1]} />

            <EmptyRow className="left-space seprator-line" />
            <CircleTextRow content={sec3Content} section={3}/>

            <EmptyRow className="left-space seprator-line" />
            <CircleTextRow content={sec4Content} />
            <EmptyRow className="left-space seprator-line" />

            <ReaderRow />
            <EmptyRow className="left-space" />
            <SingleRow />
            <EmptyRow className="rcircle-icon-containeright-space" />
            <Row>
              <Col className="circles-row ">
                <CircleIcon
                  className="circles-row__adjusted"
                  icon={circlesRow[0].img}
                >
                  <h3 className="font light s25">{t(circlesRow[0].txt)}</h3>
                </CircleIcon>
                <CircleIcon
                  className="circles-row__adjusted"
                  icon={images.circleIcon07}
                >
                  <h3 className="font light s25">{t(circlesRow[1].txt)}</h3>
                </CircleIcon>
                <CircleIcon
                  className="circles-row__adjusted"
                  icon={images.circleIcon08}
                >
                  <h3 className="font light s25">{t(circlesRow[2].txt)}</h3>
                </CircleIcon>
                <CircleIcon
                  className="circles-row__adjusted"
                  icon={images.circleIcon09}
                >
                  <h3 className="font light s25">{t(circlesRow[3].txt)}</h3>
                </CircleIcon>
              </Col>
            </Row>

            {/* <EmptyRow className="right-space" /> */}
          </Container>
        </section>
      </DefaultMain>
    </>
  );
};
export default Services;
