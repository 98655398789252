import React, { useEffect, useRef } from "react";
import { ReactComponent as LandingMap } from "assets/images/landing_map.svg";
import { branchesContent } from "content/landing";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { Spinner } from "react-bootstrap";

interface IProps {
  loading: boolean;
}

const Section5: React.FC<IProps> = ({ loading }) => {
  const { t } = useTranslation();
  const svgLandingMap = useRef<SVGSVGElement>(null);
  const { landingPage } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const { constants } = useSelector((state: RootState) => state.contentReducer);

  const updateSVG = () => {
    const svgContent = svgLandingMap?.current?.querySelectorAll("tspan");
    svgContent![0].textContent = constants?.num_branches;
    svgContent![1].textContent = lang == "en" ? "Branches" : "فرع";
    svgContent![1].setAttribute("x", lang == "en" ? "60" : "30");
    if (lang == "en") svgContent![1].classList.add("svgBranches");
    else svgContent![1].classList.remove("svgBranches");
  };

  useEffect(() => {
    updateSVG();
  }, [landingPage, lang]);

  return (
    <section className="amanmf__aman-branches">
      <div className="amanmf__aman-branches__text">
        <h2 className="f-mxl font bold  mb-3">{t(branchesContent.title)}</h2>
        <p className="f-md font light ">
          {loading ? (
            <Spinner animation="border" variant="info" />
          ) : (
            t(branchesContent.details) +
            constants?.num_branches +
            t(branchesContent.detailsContinue)
          )}
        </p>
        <div className="mt-5 w-100 search">
          <NavLink to="/branches" className="amnmf-btn-filled m-0 ">
            <span className="font bold f-sm">{t(branchesContent.btnTxt)}</span>
          </NavLink>
        </div>
      </div>
      <div className="amanmf__aman-branches__map aos-init aos-animate">
        <LandingMap ref={svgLandingMap} />
      </div>
    </section>
  );
};

export default Section5;
