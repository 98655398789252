import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import CircleIcon from "../components/CircleIcon";
import { useTranslation } from "react-i18next";

const CircleTextRow: React.FC<{ content: any, section?:any }> = ({ content, section }) => {
  const { t } = useTranslation();
  return (
    <Row className="bright-blue">
      <Col className="right-space">
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <h2 className="font bold orangeBg">{t(content.title)}</h2>
        </div>
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <p className="font light">{t(content.details)}</p>
        </div>
      </Col>
      <Col className="left-height h60 seprator-line">
        <SecRow4
          title={content.itemsList[0].txt}
          icon={content.itemsList[0].img}
          data={content}
          section={section}
        />
      </Col>
    </Row>
  );
};

const SecRow4: React.FC<{ title: string; icon: any; data: any, section:any }> = ({
  title,
  icon,
  data,
  section
}) => {
  const { t } = useTranslation();

  let [textA, textB] = data.itemsClasses;
  return (
    <>
      <div className="circles-3rd">
        <div className="left-row">
          <CircleIcon icon={""}>
            <div className="icon-text">
              <h3 className={`font bold ${textA}`}>
                {t(data.itemsList[0].circleContent[0])}
              </h3>
              <h4 className={`font bold ${textB}`}>
                {t(data.itemsList[0].circleContent[1])}
              </h4>
            </div>
          </CircleIcon>
        </div>
        <div className="left-row">
          <CircleIcon icon={""}>
            <div className="icon-text">
              <h3 className={`font bold ${textA}`}>
                {t(data.itemsList[1].circleContent[0])}
              </h3>
              <h4 className={`font bold ${textB}`}>
                {t(data.itemsList[1].circleContent[1])}
              </h4>
            </div>
          </CircleIcon>
        </div>
      </div>
      <div
        className="left-text-with-icon aos-init"
        data-aos="fade-left"
        data-aos-duration="400"
      >
        <Image src={icon} width={100} height={100} />
        <p className="font light s23">{section==3?title:t(title)}</p>
      </div>
    </>
  );
};
export default CircleTextRow;
