import React from "react";
import "../../../style/components/_loan-request-step-one.scss";
const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <p className="terms-text">
        ﺷﺮﻭﻁ ﻭﺇﺭﺷﺎﺩاﺕ ﻟﺒﺪء اﻟﺘﻤﻮﻳﻞ ﻣﻊ ﺃﻣﺎﻥ: اﻟﺸﺮﻭﻁ ﻭاﻟﺴﻤﺎﺕ اﻟﻌﺎﻣﺔ اﻟﻮاﺟﺐ ﺗﻮاﻓﺮﻫﺎ
        ﻓﻲ اﻟﻌﻤﻴﻞ
      </p>

      <ul className="terms-list">
        <li> أن يكون مصري الجنسية.</li>
        <li>ﻳﺘﺮاﻭﺡ اﻟﺴﻦ ﻣﻦ 21 ﺳﻨﺔ ﺣﺘﻰ 63 ﺳﻨﺔ.</li>
        <li>
          أﻥ ﻳﻜﻮﻥ ﻟﺪﻱ اﻟﻌﻤﻴﻞ ﻧﺸﺎﻁ ﻗﺎﺋﻢ ﻣﺪﺭ ﻟﻠﺪﺧﻞ ﻳﻔﻲ ﺑﺴﺪاﺩ ﻣﺒﻠﻎ اﻟﺘﻤﻮﻳﻞ ﻭﺃﻋﺒﺎﺋﻪ.
        </li>
        <li>أﻥ ﻳﻜﻮﻥ ﻟﺪﻳﻪ ﺑﻄﺎﻗﺔ ﺭﻗﻢ ﻗﻮﻣﻲ ﺳﺎﺭﻳﺔ.</li>
        <li>ألا يكون ضامنا لأحد العملاء القائمين بالشركة.</li>
        <li>
          أﻥ ﻳﻜﻮﻥ ﻟﺪﻳﻪ ﺿﺎﻣﻦ ﻳﺘﻤﺘﻊ ﺑﺴﻤﻌﺔ ﺟﻴﺪﺓ ﻣﻦ ﺃﺣﺪ ﺃﻗﺎﺭﺑﻪ ﻣﻦ اﻟﺪﺭﺟﺔ اﻷﻭﻟﻲ (اﻷﺏ
          - اﻷﻡ - اﻟﺰﻭﺟﺔ - اﻹﺑﻦ - اﻹﺑﻨﻪ ) ﻭﻓﻲ ﺣﺎﻟﺔ ﻋﺪﻡ ﺗﻮاﻓﺮ ﺃﺣﺪ اﻷﻗﺎﺭﺏ ﻣﻦ
          اﻟﺪﺭﺟﺔ اﻷﻭﻟﻲ ﻳﻜﻮﻥ اﻟﻀﺎﻣﻦ ﺃﺣﺪ اﻷﺷﺨﺎﺹ ﺫﻭ اﻟﺪﺧﻞ اﻟﺜﺎﺑﺖ ( ﻣﻮﻇﻒ ﺃﻭ ﺻﺎﺣﺐ
          ﻣﻌﺎﺵ ).
        </li>
        <li>أﻥ ﻳﻘﺪﻡ اﻟﻌﻤﻴﻞ ﻣﺎ ﻳﺜﺒﺖ ﻣﻜﺎﻥ ﻣﺰاﻭﻟﺘﻪ ﻟﻠﻨﺸﺎﻁ ﻭﻣﺤﻞ ﺳﻜﻨﻪ اﻟﺪاﺋﻢ.</li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
