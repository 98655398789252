import React, { useEffect, useRef } from "react";
import { appAdv } from "content/landing";
import { ReactComponent as KitePath } from "assets/images/kite-path.svg";
import AppAdvCard from "../components/AppAdvCard";
import { useTranslation } from "react-i18next";

const Section4: React.FC = () => {
  const { t } = useTranslation();
  const kiteRef = useRef<HTMLDivElement>(null);
  const cardsContent = appAdv.items.map((el, i) => (
    <AppAdvCard key={i} content={el} />
  ));

  const elementInView = (el: any, scrollOffset = 150) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) -
        scrollOffset
    );
  };

  useEffect(() => {
    const kiteIcon = kiteRef.current;
    document.addEventListener("scroll", (e) => {
      if (elementInView(kiteIcon)) {
        kiteIcon?.classList.add("aos-animate");
      } else {
        kiteIcon?.classList.remove("aos-animate");
      }
    });
  });

  return (
    <section className="amanmf__loan-benefits">
      <h2 className="font bold f-mxl amanmf__loan-benefits__title">
        {t(appAdv.title)}
      </h2>
      <div className="amanmf__benefits-container">{cardsContent}</div>
      <div className="path-container aos-init" ref={kiteRef}>
        <KitePath />
      </div>
    </section>
  );
};

export default Section4;
