import React, { useEffect, useRef } from "react";
import images from "assets/images";
import { mobileAppContent } from "content/landing";
import { useTranslation } from "react-i18next";
import { ReactComponent as KitePath } from "assets/images/mobApp-kit.svg";
import MobileAppItem from "../components/MobileAppItem";

const Section7: React.FC = (props) => {
  const { t } = useTranslation();
  const kiteRef = useRef<HTMLDivElement>(null);

  const content = mobileAppContent.map((el, i) => (
    <MobileAppItem key={i} data={el} />
  ));

  const isElementInView = (el: any, scrollOffset = 150) => {
    const elementTop = el.getBoundingClientRect().top;
    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) -
        scrollOffset
    );
  };
  useEffect(() => {
    const kiteIcon = kiteRef.current;
    document.addEventListener("scroll", (e) => {
      if (window.innerWidth > 1200) {
        if (isElementInView(kiteIcon, -350)) {
          kiteIcon?.classList.add("aos-animate");
        } else {
          kiteIcon?.classList.remove("aos-animate");
        }
      }
    });
  }, []);

  return (
    <section className="amanApp__advertise__section amanmf__mobile-app">
      <div className="amanmf__mobile-app__content">
        <h2 className="text-white font bold">
          {t("Aman App")}
          <br />
          {t("In your hands now")}
        </h2>
        {window.innerWidth <= 1200 && (
          <img
            src={images.secondAmanAppAdvertise}
            alt="Aman App Advertise"
            className="amanApp__advertise__bottomImg iphone-small--lg-screen"
          />
        )}
        <p className="text-white font light fs-5">
          {t("Applying for financing is now easier through")}
          <br />
          {t("Aman Microfinance Application")}
        </p>
        <ul className="amanApp__advertise__checkList-items mt-5">{content}</ul>
        <div className="mt-5 amanApp__advertise__appStore">
          <a
            href="https://play.google.com/store/apps/details?id=com.amanmf&pli=1"
            target={"_blank"}
            className="transparent-btn"
          >
            <img src={images.appAdvertiseGoogleStore} alt="App Store" />
          </a>
          {/* <img src={images.appAdvertiseAppleStore} alt="App Store" /> */}
        </div>
      </div>
      {window.innerWidth > 1200 && (
        <div
          className="amanmf__mobile-app__image aos-init aos-animate"
          ref={kiteRef}
        >
          <img
            src={images.secondAmanAppAdvertise}
            alt="Aman App Advertise"
            className="amanApp__advertise__bottomImg iphone-small--lg-screen"
          />
          <KitePath />
        </div>
      )}
    </section>
  );
};

export default Section7;
