import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import LoanRequestModal from "./components/LoanRequestModal";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { loanRequestContent } from "content/loan_request";
import LoanRequestCarousel from "./components/LoanRequestCarousel";
import LoanRequestForm from "./components/LoanRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { GeneralLoanType } from "types/backend.modal";
import { RootState } from "store/configStore";
import { useLocation, useNavigate } from "react-router-dom";
import images from "assets/images";
import { Helmet } from "react-helmet";
import FormModal from "./components/FormModal";
import StepOne from "./components/StepOne";
import TermsAndConditions from "./components/TermsAndConditions";
import Sorry from "./components/Sorry";
import Success from "./components/success";
import StepTwo from "./components/StepTwo";
import { useSearchParams } from "react-router-dom";
import ErrorContent from "./components/ErrorContent";
interface Props {
  type: GeneralLoanType;
  amountOfLoans: any[];
  API_getAmountsActionThunk: any;
  API_postFormActionThunk: any;
  API_StepOneOCRActionThunk: any;
  API_ValidatePromoCodeActionThunk: any;
  API_GetLeadMarket: any;
}

const LoanRequestMF: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const loc = useLocation();
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const [success, setSuccess] = useState({ sent: false, error: false });
  const dispatch = useDispatch();
  const [isMainModalVisible, setIsMainModalVisible] = useState<boolean>(false);
  const [isSubModalVisible, setIsSubModalVisible] = useState<boolean>(false);
  const [subModalContent, setSubModalContent] = useState<
    "success" | "sorry" | "error" | "terms" | null
  >(null);
  const [titleAction, setTitleAction] = useState<"close" | "back">("close");
  const navigate = useNavigate();
  /**/
  const [step, setStep] = useState(1);
  const goToNextStep = () => setStep((prev) => prev + 1);
  const onBack = () => setStep((prev) => (prev > 1 ? prev - 1 : 1));
  const [checked, setChecked] = useState(false);
  const [selectedFrontImage, setSelectedFrontImage] = useState<string | null>(
    null
  );
  const [selectedBackImage, setSelectedBackImage] = useState<string | null>(
    null
  );
  const [fullName, setFullName] = useState<string>("");
  const [nationalID, setNationalID] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const vodafoneRedirected = searchParams.get("vodafoneRedirected") === "true";
  const hasPromoCode = searchParams.get("hasPromoCode") === "true";
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isOrangeHovered, setIsOrangeHovered] = useState(false);
  const [isBlueHovered, setIsBlueHovered] = useState(false);
  let full_name_ocr = "";
  const isAgeValid = (dobString: string) => {
    const dateOfBirth = new Date(dobString); // Parse the date of birth
    const today = new Date(); // Current date

    // Calculate age
    const age = today.getFullYear() - dateOfBirth.getFullYear();
    const isBirthdayPassedThisYear =
      today.getMonth() > dateOfBirth.getMonth() ||
      (today.getMonth() === dateOfBirth.getMonth() &&
        today.getDate() >= dateOfBirth.getDate());

    // Adjust age if birthday hasn't passed this year
    const exactAge = isBirthdayPassedThisYear ? age : age - 1;

    // Check if the age is between 21 and 63 (inclusive)
    return exactAge >= 21 && exactAge <= 63;
  };

  /* */
  const handleOCR = async (e: any) => {
    e.preventDefault();
    setIsSending(true);

    const resMessage: any = await dispatch(
      props.API_StepOneOCRActionThunk({
        FrontPhotoBasestring64: selectedFrontImage,
        BackPhotoBasestring64: selectedBackImage,
        MobileNumber: "01068952047",
        Lang: 0,
      })
    );
    setIsSending(false);
    if (resMessage.status == 200 && resMessage.data.ResultID == 0) {
      setNationalID(resMessage.data.ocr.result.front_nid);
      if (isAgeValid(resMessage.data.ocr.result.date_of_birth)) {
        goToNextStep();
        //
      } else {
        // props.setSuccess({ sent: true, error: true });
        setIsSubModalVisible(true);
        setSubModalContent("sorry");
      }

      full_name_ocr =
        resMessage.data.ocr.result.first_name +
        " " +
        resMessage.data.ocr.result.full_name;
      // navigate('/loan/thanks');
      setFullName(full_name_ocr);
      // props.setSuccess({ sent: true, error: false });
    } else {
      // props.setSuccess({ sent: true, error: true });
      setIsSubModalVisible(true);
      setSubModalContent("sorry");
    }
    // clearForm();
    return resMessage;
  };
  useEffect(() => {
    setSuccess({ sent: false, error: false });
  }, [loc.pathname]);
  useEffect(() => {
    if (isMainModalVisible || isSubModalVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isMainModalVisible, isSubModalVisible]);
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            selectedBackImage={selectedBackImage}
            selectedFrontImage={selectedFrontImage}
            checked={checked}
            setChecked={setChecked}
            setSelectedBackImage={setSelectedBackImage}
            setSelectedFrontImage={setSelectedFrontImage}
            setIsSubModalVisible={setIsSubModalVisible}
            goToNextStep={goToNextStep}
            subModalContent={subModalContent}
            setSubModalContent={setSubModalContent}
            handleOCR={handleOCR}
          />
        );

        break;
      case 2:
        return (
          <StepTwo
            setSuccess={setSuccess}
            type={props.type}
            API_postFormActionThunk={props.API_postFormActionThunk}
            API_ValidatePromoCodeThunk={props.API_ValidatePromoCodeActionThunk}
            amountOfLoans={props.amountOfLoans}
            setSubModalContent={setSubModalContent}
            setIsSubModalVisible={setIsSubModalVisible}
            selectedBackImage={selectedBackImage}
            selectedFrontImage={selectedFrontImage}
            nationalID={nationalID}
            fullName={fullName}
            setFullName={setFullName}
          />
        );

      default:
        return null;
        break;
    }
  };
  const renderSubModalContent = () => {
    switch (subModalContent) {
      case "success":
        return (
          <Success
            setIsSubModalVisible={setIsSubModalVisible}
            setIsMainModalVisible={setIsMainModalVisible}
            setStep={setStep}
          />
        );
      case "sorry":
        return <Sorry setIsSubModalVisible={setIsSubModalVisible} />;
      case "error":
        return <ErrorContent setIsSubModalVisible={setIsSubModalVisible} />;
      case "terms":
        return <TermsAndConditions />;
      default:
        return null;
    }
  };
  const renderSubModalTitle = () => {
    switch (subModalContent) {
      case "success":
        return null;
      case "sorry":
        return null;
      case "error":
        return null;
      case "terms":
        return (
          <h1 className="loan-request-section-sub-modals-title">
            الشروط والأحكام
          </h1>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (step === 2) {
      setTitleAction("back");
    } else if (step === 1) {
      setTitleAction("close");
    }
  }, [step]);
  return (
    <>
      <Helmet>
        <title>
          {"Request Loan " + props.type + " - amanmicrofinance.com"}
        </title>
      </Helmet>

      <DefaultMain>
        <section className="loan-request-section">
          <div className="loan-request-form-container-inner">
            <div className="title-container">
              <h1
                className="font bold carousel-header"
                style={{ fontFamily: "Cairo" }}
              >
                احصل على تمويل لمشروعك
                <span
                  className="loan-request-form-container-inner-hours"
                  style={{ fontFamily: "Cairo" }}
                >
                  <span
                    style={{
                      fontFamily: "Cairo",
                      color: "#01828D",
                      fontWeight: "700",
                      display: "unset",
                    }}
                  >
                    {"  "}خلال
                  </span>
                  {t(loanRequestContent.hours)}
                </span>
              </h1>
              <div className="btn-container">
                <button
                  onClick={() => {
                    setIsMainModalVisible(true);
                  }}
                  style={{
                    backgroundColor: isOrangeHovered
                      ? "transparent"
                      : "#E9581E",
                    color: isOrangeHovered ? "#E9581E" : "#FDFDFD",
                    border: isOrangeHovered ? "2px solid #E9581E" : "none",
                  }}
                  onMouseEnter={() => setIsOrangeHovered(true)}
                  onMouseLeave={() => setIsOrangeHovered(false)}
                >
                  <p
                    style={{
                      fontFamily: "Almarai",
                      fontWeight: "700",
                      fontSize: "36px",
                    }}
                  >
                    {t(loanRequestContent.requestYourFund)}
                  </p>
                </button>
                <button
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  style={{
                    backgroundColor: isBlueHovered ? "transparent" : "#02A9BF",
                    color: isBlueHovered ? "#02A9BF" : "#FDFDFD",
                    border: isBlueHovered ? "2px solid #02A9BF" : "none",
                  }}
                  onMouseEnter={() => setIsBlueHovered(true)}
                  onMouseLeave={() => setIsBlueHovered(false)}
                >
                  <p
                    style={{
                      fontFamily: "Almarai",
                      fontWeight: "700",
                      fontSize: "36px",
                    }}
                  >
                    {t(loanRequestContent.ContactUs)}
                  </p>
                </button>
              </div>
            </div>
            <div className="img-container">
              <img
                src={
                  hasPromoCode || vodafoneRedirected
                    ? images.amanVodafoneLanding
                    : images.loan_landing_page_img
                }
              />
            </div>
          </div>
          <div
            className="submit-section"
            style={
              hasPromoCode || vodafoneRedirected
                ? {
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }
                : {}
            }
          >
            <button
              onClick={() => {
                setIsMainModalVisible(true);
              }}
              style={{
                backgroundColor: "#E9581E",
                width: "22rem",
                height: "3.5rem",
                color: "#FDFDFD",
                border: "none",
                padding: 0,
                minHeight: "unset",
                boxShadow:
                  "inset 2px 4px 4px rgba(0, 0, 0, 0.25), inset -2px -4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onMouseEnter={() => setIsOrangeHovered(true)}
              onMouseLeave={() => setIsOrangeHovered(false)}
            >
              <p
                style={{
                  fontFamily: "Almarai",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                {t(loanRequestContent.requestYourFund)}
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/contact-us");
              }}
              style={{
                backgroundColor: "#FDFDFD",
                color: "#02A9BF",
                border: "none",
                width: "22rem",
                height: "3.5rem",
                padding: 0,
                minHeight: "unset",
                boxShadow:
                  "inset 2px 4px 4px rgba(0, 0, 0, 0.25), inset -2px -4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onMouseEnter={() => setIsBlueHovered(true)}
              onMouseLeave={() => setIsBlueHovered(false)}
            >
              <p
                style={{
                  fontFamily: "Almarai",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                {t(loanRequestContent.ContactUs)}
              </p>
            </button>
          </div>
        </section>
      </DefaultMain>
      {isMainModalVisible && (
        <FormModal
          isVisible={isMainModalVisible}
          submitTitle={"التالى"}
          onClose={() => {
            setIsMainModalVisible(false);
          }}
          onBack={onBack}
          headerStyle={{ justifyContent: "unset" }}
          icon={titleAction}
        >
          {renderStep()}
        </FormModal>
      )}
      {isSubModalVisible && (
        <FormModal
          isVisible={isMainModalVisible}
          submitTitle={"حسنا"}
          title={renderSubModalTitle()}
          onClose={() => {
            setIsSubModalVisible(false);
          }}
          icon={"close"}
          isDisabled={false}
          containerStyle={{
            maxHeight: "550px",
            maxWidth: "600px",
          }}
        >
          {renderSubModalContent()}
        </FormModal>
      )}
    </>
  );
};
export default LoanRequestMF;
