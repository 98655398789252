import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  img: any;
}

const AppAdvCard: React.FC<{ content: Props }> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <div className="amanmf__benefit-card">
      <div className="amanmf__img-container">
        <Image src={content.img} width={165} height={115} alt="card image" />
      </div>
      <p className="font bold f-l amanmf__benefit-text">{t(content.text)}</p>
    </div>
  );
};

export default AppAdvCard;
