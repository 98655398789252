import React from "react";
import { NavLink } from "react-router-dom";
import images from "assets/images";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Section2: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="about__section">
      <div className="about__section__img col-lg-2">
        <Image src={images.amanLogo} alt="AmanLogo" className="w-100" />
      </div>
      <div className="about__section__content col-lg-7">
        <h1 className="f-xl mb-4 font bold">
          {t(
            "Aman Microfinance is the latest Raya companies, providing financial services to business owners and small and micro enterprises in all governorates"
          )}
        </h1>
        <p className="mb-5 f-sm font light">
          {t(
            "Aman Microfinance Company provides easy financing methods, based on innovation, continuous development, an understanding of market needs, and speed in providing the financing needs of its clients in a timely manner."
          )}
        </p>
        <NavLink className="amnmf-btn-outlined font bold" to={"/services"}>
          {t("More of our services")}
        </NavLink>
      </div>
    </section>
  );
};
export default Section2;
