import React, { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Accordion, Col, Row, Card } from "react-bootstrap";
import images from "assets/images";
import { CareersItem } from "types";
import { rawHtmlToReact } from "helper/HtmlToReact";
import CustomToggle from "./CustomToggle";

interface IProps {
  data: [CareersItem];
}

const JobsAccordion: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [collapsedIndex, setCollapsedIndex] = useState(-1);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = (event: SyntheticEvent, index: number) => {
    setIsCollapsed(false);
    setCollapsedIndex(index);
    if (collapsedIndex === index) {
      setIsCollapsed(true);
      setCollapsedIndex(-1);
    }
  };

  const getLocation = (location: string) => {
    const locationArray = location.split(" ");
    return `${locationArray[0]}-${locationArray[1]}`;
  };

  const handleApplyNow = (event: SyntheticEvent, jobId: number) => {
    navigate(`/careers/${jobId}`);
  };

  return (
    <Accordion>
      {data?.map((job: CareersItem, index: number) => (
        <Card className="job-row" key={index}>
          <Card.Header>
            <Row className={"job-accordion-title px-2"}>
              <Col xs={12} lg={8}>
                <div className="job-title-container mb-1">
                  <CustomToggle
                    eventKey={`${index}`}
                    accordionCollapse={collapsedIndex === index ? false : true}
                    isCollapsed={isCollapsed}
                    onClick={(event) => handleCollapse(event, index)}
                  />
                  <h2 className="font bold mb-2">{job.header}</h2>
                </div>
              </Col>
              <Col xs={10} lg={4} className={"accordion-labels-container"}>
                <div className="accordion-labels-row">
                  <div className="accordion-labels">
                    <img
                      src={images.clock}
                      alt={"clock"}
                      className={"label-icon"}
                    />
                    <p>{job.type}</p>
                  </div>
                  <div className="accordion-labels">
                    <img
                      src={images.location}
                      alt={"location"}
                      className={"label-icon"}
                    />
                    <p>{getLocation(job.location)}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={`job-accordion-paragraph mb-3`}>
              <Col xs={10} md={7}>
                <p className="font light">{rawHtmlToReact(job.paragraph)}</p>
              </Col>
              <Col xs={10} md={4} className={"left-column"}>
                <button
                  className="amnmf-btn-filled"
                  onClick={(event) => handleApplyNow(event, job.id)}
                >
                  {t("Apply now")}
                </button>
              </Col>
            </Row>
          </Card.Header>
          <Accordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              <section className="details-container m-auto">
                <section>
                  <div className="mb-3">
                    <h3 className="font bold">{t("Responsibilites")}</h3>
                    {rawHtmlToReact(job.responsibilities)}
                  </div>
                </section>
                <section>
                  <div>
                    <h3 className="font bold">{t("Requirements")}</h3>
                    {rawHtmlToReact(job.requirements)}
                  </div>
                </section>
              </section>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};
export default JobsAccordion;
