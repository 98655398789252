import React, { CSSProperties, FC } from "react";
import "../../../style/components/_form-modal.scss";
import images from "assets/images";
interface Props {
  isVisible?: boolean;
  onClose: () => void;
  submitTitle: string;
  title?: JSX.Element | null;
  icon: "close" | "back";
  containerStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  isDisabled?: boolean;
  onBack?: () => void;
}
const FormModal: FC<Props> = ({
  isVisible,
  onClose,
  title,
  children,
  icon,
  containerStyle,
  contentStyle,
  footerStyle,
  headerStyle,
  submitTitle,
  isDisabled,
  onBack,
}) => {
  return (
    <div className="form-modal-overlay">
      <div className="form-modal-container" style={containerStyle}>
        {/* Header Section */}
        <div
          className="form-modal-header"
          style={{
            ...headerStyle,
            direction: icon === "close" ? "ltr" : "rtl",
          }}
        >
          <button
            className="form-modal-close"
            onClick={icon === "close" ? onClose : onBack}
          >
            {icon === "close" ? (
              <img src={images.icon_close_blue} />
            ) : (
              <img src={images.icon_back_blue} />
            )}
          </button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1",
            }}
          >
            {title}
          </div>
        </div>

        {/* Content Section */}
        <div className="form-modal-body" style={contentStyle}>
          {children}
        </div>

        {/* Footer Section */}
      </div>
    </div>
  );
};

export default FormModal;
