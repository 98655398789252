import React, { useEffect, useState } from "react";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import FormInput from "./FormInput";
import FormCheckInput from "./FormCheckInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { Branch, Government } from "types";
import { API_postOnlineLoanReq } from "store/actions/loanReqActions";
import CommonModal from "components/CommonModal";
import { useNavigate } from "react-router-dom";


const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const OnlineMerchantForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [isSentSucc, setIsSentSucc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [isFullNameValid, setIsFullNameValid] = useState<boolean | undefined>(
    undefined
  );
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<
    boolean | undefined
  >(undefined);

  const [addressInput, setAddressInput] = useState("");
  const [loanPurposeInput, setLoanPurposeInput] = useState("");
  const [phoneNumber, setPhoneInput] = useState("");
  const [checkedInput, setCheckedInput] = useState("No");
  const [selectedGovernment, setSelectedGovernment] = useState("0");
  const [selectedBranch, setSelectedBranch] = useState("0");
  const [allBranches, setAllBranches] = useState<Branch[]>([]);

  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const dispatch = useDispatch();

  const handelFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };
  const handelAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressInput(e.target.value);
  };
  const handelPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneInput(e.target.value);
  };
  const handelLoanPurposeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoanPurposeInput(e.target.value);
  };
  const changeSelectGovernmentHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedGovernment(e.target.value);
  };
  const changeSelectBranchHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedBranch(e.target.value);
  };
  const checkInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedInput(e.target.value);
  };
  const isSubmitDisabled = () =>
    !isFullNameValid ||
    checkedInput == "No" ||
    loanPurposeInput.length == 0 ||
    addressInput.length == 0 ||
    selectedGovernment == "0" ||
    selectedBranch == "0" ||
    !isPhoneNumberValid;

  useEffect(() => {
    if (selectedGovernment !== "0") {
      const selectedGovernmentObj = governments.filter(
        (govern: Government) => govern.id == Number(selectedGovernment)
      )[0];
      setAllBranches([...selectedGovernmentObj.branches]);
    } else {
      setAllBranches([]);
    }
    setSelectedBranch("0");
  }, [selectedGovernment, governments]);
  const resetForm = () => {
    setFullName("");
    setAddressInput("");
    setPhoneInput("");
    setLoanPurposeInput("");
    setSelectedGovernment("0");
    setSelectedBranch("0");
    setCheckedInput("No");
  };

  const submitFormHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const res: any = await dispatch(
      API_postOnlineLoanReq({
        full_name: fullName,
        phone_number: phoneNumber,
        address: addressInput,
        purpose_of_loan: loanPurposeInput,
        government: Number(selectedGovernment),
        branch: Number(selectedBranch),
      })
    );
    setIsModalOpen(true);
    if (res.status == 200) {
      navigate('/loan/thanks');
      setIsSentSucc(true);
    } else {
      setIsSentSucc(false);
    }
    resetForm();
  };
  useEffect(() => {
    if (fullName.length > 0)
      setIsFullNameValid(
        /^[\u0621-\u064A| +]+$/.test(fullName.trim()) ||
        /^[a-zA-Z| +]+$/.test(fullName.trim())
      );
    else setIsFullNameValid(undefined);
  }, [fullName]);
  useEffect(() => {
    if (phoneNumber.length > 0)
      setIsPhoneNumberValid(EgyptionNumbersRegex.test(phoneNumber));
    else setIsPhoneNumberValid(undefined);
  }, [phoneNumber]);

  return (
    <div className="col-lg-6 col-sm-12 bg-white h-100 request__form__container">
      <img
        src={images.artArrowLevel2}
        alt="Art Arrow"
        className="request__form__img"
      />
      <Form className="request__form" onSubmit={submitFormHandler}>
        <div className="request__form__header">
          <h3 className="font bold pt-3">
            {t("Online merchant financing request")}
          </h3>
          <FormInput
            inputLength={250}
            content={"Full Name"}
            inputType={"text"}
            value={fullName}
            change={handelFullNameChange}
          />
          {isFullNameValid === false && (
            <div className="error-state mb-3" style={{ flexDirection: "row" }}>
              <img src={images.errorIcon} />
              <p>{t("Please enter valid full name")}</p>
            </div>
          )}

          <FormInput
            inputLength={11}
            content={t("Mobile Phone")}
            inputType={"phone"}
            value={phoneNumber}
            change={handelPhoneChange}
          />
          {isPhoneNumberValid === false && (
            <div className="error-state" style={{ flexDirection: "row" }}>
              <img src={images.errorIcon} />
              <p style={{ paddingTop: 0 }}>
                {t("Please enter valid phone number")}
              </p>
            </div>
          )}
        </div>

        <div className="request__form__bottom__side">
          <h6 className="font bold">{t("Activity Information")}</h6>
          <Form.Group className="mb-3 d-flex align-items-baseline yes_or_no_question">
            <Form.Label column lg={8} className="font light">
              {t(
                "Do you have an existing business that has been in existence for at least a year?"
              )}{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="d-flex yes_or_no_container">
              <FormCheckInput
                content={"Yes"}
                changeHandler={checkInputChangeHandler}
                inputValue={checkedInput}
              />
              <FormCheckInput
                content={"No"}
                changeHandler={checkInputChangeHandler}
                inputValue={checkedInput}
              />
            </div>
          </Form.Group>
          <FormInput
            inputLength={250}
            content={"The title of the existing activity"}
            inputType={"text"}
            value={addressInput}
            change={handelAddressChange}
          />
          <Form.Label lg={4} className="font light">
            {t("The branch closest to the workplace")}{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Row className="mb-3 location-input">
            <Form.Group as={Col} lg={6}>
              <Form.Select
                value={selectedGovernment}
                onChange={changeSelectGovernmentHandler}
                id="governorateId"
              >
                {
                  <>
                    <option value="0">{t("Choose a Governorate..")}</option>
                    {governments?.map((govern: any) => (
                      <option key={govern.id} value={govern.id}>
                        {govern.name}
                      </option>
                    ))}
                  </>
                }
              </Form.Select>
            </Form.Group>
            <FormGroup as={Col} lg={6}>
              <Form.Select
                disabled={selectedGovernment == "0"}
                value={selectedBranch}
                id="branchesId"
                onChange={changeSelectBranchHandler}
              >
                {
                  <>
                    {selectedGovernment !== "0" ? (
                      <option value="0">{t("Choose Branch")}</option>
                    ) : null}
                    {allBranches?.map((branch: any) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </>
                }
              </Form.Select>
            </FormGroup>
          </Row>
          <FormInput
            inputLength={500}
            content={"Purpose of Funding"}
            inputType={"text"}
            value={loanPurposeInput}
            change={handelLoanPurposeChange}
          />
          <p className="font mt-5">
            <img src={images.errorIcon} alt="Error" className="mx-2" />
            {t("The activity must be over a year old")}
          </p>
          <button
            type="submit"
            className="btn w-100 font bold rounded-pill text-white p-3 btn__request"
            disabled={isSubmitDisabled()}
          >
            {t("Send Request")}
          </button>
        </div>
      </Form>
      <CommonModal
        isSuccess={isSentSucc}
        successText="Your Loan request Has been sent successfully"
        errorText="An Error Occured Please try again"
        show={isModalOpen}
        handleClose={setIsModalOpen}
      />
    </div>
  );
};
export default OnlineMerchantForm;
