import images from "assets/images";
import React, { FC } from "react";
import Lottie from "lottie-react";
interface Props {
  setIsSubModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const Sorry: FC<Props> = ({ setIsSubModalVisible }) => {
  return (
    <div className="sorry-modal-container">
      {/*  <img src={images.sorryAnimation} alt="Sorry" /> */}
      <Lottie
        animationData={images.sorryAnimation}
        loop={true}
        width={177}
        height={177}
        // style={{ width: "200px", height: "250px" }}
      />
      <h1>عذرًا</h1>
      <p>
        يبدو أنك غير مطابق لشروط الحصول على التمويل يمكنك التقديم باستخدام بطاقة
        رقم قومي مختلفة
      </p>
      <div
        className="form-modal-footer"
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingInlineEnd: "none",
          paddingInline: "2rem",
        }}
      >
        <button
          className="form-modal-close-button"
          onClick={() => setIsSubModalVisible(false)}
          disabled={false}
          style={{
            fontSize: "20px",
            fontWeight: "700",
            fontFamily: "Tajawal",
          }}
        >
          قدم الان
        </button>
      </div>
    </div>
  );
};

export default Sorry;
