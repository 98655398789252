import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { loanRequestContent } from "content/loan_request";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { Branch, Government } from "types";
import { GeneralLoanType, VseLoanReqData } from "types/backend.modal";
import CommonOrangeButton from "components/Button";
import { API_getPhoneNumerLoanStatus } from "store/actions/loanReqActions";
import images from "assets/images";
import CommonModal from "components/CommonModal";
import axios from "axios";
import { isTemplateExpression } from "typescript";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

interface Props {
  type: GeneralLoanType;
  amountOfLoans: any[];
  API_postFormActionThunk: (
    data: VseLoanReqData
  ) => (dispatch: React.Dispatch<any>) => Promise<any>;
  setSuccess: Function;
}
type staticOptionsObject = {
  id: number;
  description: string;
  value: string;
};

const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const LoanRequestForm: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const [fullName, setFullName] = useState("");
  const [isFullNameValid, setIsFullNameValid] = useState<boolean | undefined>(
    undefined
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [displayedGovernments, setDisplayedGovernments] = useState<
    Government[]
  >([]);
  const [displayedBranches, setDisplayedBranches] = useState<Branch[]>([]);
  const [isSentSucc, setIsSentSucc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isCheckBtnShown, setIsCheckBtnShown] = useState(true);
  const [selectedGovernmentId, setSelectedGovernmentId] = useState("0");
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedAmountOfLoanId, setSelectedAmountOfLoanId] = useState("0");
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [hasWorkingProject, setHasWorkingProject] = useState(false);
  const [hasOfficialDocuments, setHasOfficialDocuments] = useState(false);
  const [salesOverOneMillion, setSalesOverOneMillion] = useState(false);
  const [isSendingPhoneNumber, setIsSendingPhoneNumber] = useState(false);
  const [isPhoneNumberEgyption, setIsPhoneNumberEgyption] = useState<
    boolean | undefined
  >(undefined);
  const [isSubmitShown, setIsSubmitShown] = useState(false);
  const [isNumberAlreadyApplied, setIsNumberAlreadyApplied] = useState(false);
  const [typesOfMontag, setTypesOfMontag] = useState([]);
  const [govermentsList, setGovermentsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [city, setCity] = useState("-1");
  const [productType, setProductType] = useState({
    id: "-1",
    name: "",
  });
  const [transportProduct, setTransportProduct] = useState({
    id: "-1",
    name: "",
  });

  // STATIC DATA
  const projectTypes: staticOptionsObject[] = [
    { id: 1, description: t("Commercial"), value: "commercial" },
    { id: 2, description: t("Industrial"), value: "industrial" },
    { id: 3, description: t("Service"), value: "service" },
    { id: 4, description: t("Agricultural"), value: "agricultural" },
  ];

  const transportationProductType = [
    {
      id: 1,
      name: "موتورسيكل",
    },
    {
      id: 2,
      name: "تروسيكل",
    },
  ];

  const productTypeList = [
    {
      id: 9,
      name: "برنامج تمويل عملاء مشروعات صغيرة جدا (اسلامي)",
    },
    {
      id: 8,
      name: "برنامج تمويل عملاء مشروعات صغيرة جدا ",
    },
  ];

  const clearForm = () => {
    setFullName("");
    setPhoneNumber("");
    setSelectedGovernmentId("0");
    setSelectedBranchId("0");
    setSelectedProductId("");
    setSelectedAmountOfLoanId("0");
    setDisplayedBranches([]);
    setIsCheckBtnShown(true);
    setIsSubmitShown(false);
  };

  const sendLoanReq = async (e: any) => {
    e.preventDefault();
    setIsSending(true);
    console.log("product_type: String(productType.name), : ", productType);

    const resMessage: any = await dispatch(
      props.API_postFormActionThunk({
        amount_of_loan: selectedAmountOfLoanId,
        product_id: selectedAmountOfLoanId,
        full_name: fullName,
        government: Number(city),
        branch: Number(selectedBranchId),
        product_type: String(productType.name),
        product: String(selectedProductId),
        phone_number: phoneNumber,
        category: props.type === "lv" ? "mf" : props.type,
        sales_over_one_million: salesOverOneMillion,
        have_official_document: hasOfficialDocuments,
        activity_type: selectedProjectType,
        have_existing_activity: hasWorkingProject,
        transport_poduct_type: transportProduct.name,
        transport_poduct_type_id: transportProduct.id,
      })
    );

    setIsSending(false);
    if (resMessage.status == 200 || resMessage.status == 201) {
      navigate("/loan/thanks");
      props.setSuccess({ sent: true, error: false });
    } else {
      props.setSuccess({ sent: true, error: true });
    }
    clearForm();
  };
  const checkPhoneNumber = async (e: any) => {
    e.preventDefault();
    setIsSendingPhoneNumber(true);
    const isUserNumberAvaliable = (await dispatch(
      API_getPhoneNumerLoanStatus(phoneNumber, props.type)
    )) as unknown as boolean;
    setIsSendingPhoneNumber(false);
    if (isUserNumberAvaliable) {
      setIsCheckBtnShown(false);
      setIsSubmitShown(true);
      setIsNumberAlreadyApplied(false);
    } else {
      setIsNumberAlreadyApplied(true);
    }
  };
  const isSendDisabled = () => {
    if (props.type === "mf") {
      return (
        !isFullNameValid ||
        fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        !hasWorkingProject ||
        selectedAmountOfLoanId == "0" ||
        selectedBranchId == "0"
      );
    } else if (props.type === "sme") {
      return (
        !isFullNameValid ||
        fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        !hasOfficialDocuments ||
        selectedBranchId == "0"||
        selectedProductId ==""
      );
    } else if (props.type === "lv") {
      return (
        !isFullNameValid ||
        fullName.length == 0 ||
        city === "-1" ||
        !isPhoneNumberEgyption ||
        selectedAmountOfLoanId == "0" ||
        selectedBranchId == "0" ||
        transportProduct.id == "-1"
      );
    }
  };

  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/content/amount-of-loans?activityType=${
          props.type == "lv" ? "mf" : props.type
        }`
      )
      .then((res) => {
        setTypesOfMontag(res.data.Data);
        console.log("GET ACTIVITY TYPE:", res);
      })
      .catch(() => {});

    axios
      .get(
        `https://mf.amanmicrofinance.com/api/company/governemnts?category=${
          props.type == "lv" ? "mf" : props.type
        }`
      )
      .then((res) => {
        setGovermentsList(res.data.Data);
        console.log("GET GOVERNMENT CATEGORIES SUCECESS: ", res);
      })
      .catch((err) => {
        console.log("GET GOVERNMENT CATEGORIES FAILED: ", err);
      });
  }, [props.type]);

  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/company/branches?governmentId=${city}&activityType=${
          props.type == "lv" ? "mf" : props.type
        }`
      )
      .then((res) => {
        setBranchesList(res.data.Data);
        console.log("GET BRANCHES SUCCESS: ", res);
      })
      .catch((err) => {
        console.log("GET BRANCHES FAILED: ", err);
      });
  }, [city]);



  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/company/get_vse_products?activityType=${productType.id}`)
      .then((res) => {
        setProductsList(res.data.Data);
        console.log("GET PRODUCTS SUCCESS: ", res);
        console.log("productType", productType)
      console.log("selectedProductId", selectedProductId)
      })
      .catch((err) => {
        console.log("GET PRODUCTS FAILED: ", err);
      });
  }, [productType]);

  useEffect(() => {
    if (fullName.length > 0)
      setIsFullNameValid(
        (/^[\u0621-\u064A| +]+$/.test(fullName.trim()) ||
          /^[a-zA-Z| +]+$/.test(fullName.trim())) &&
          fullName.trim().length > 10
      );
    else setIsFullNameValid(undefined);
  }, [fullName]);

  // useEffect(() => {
  //   if (governments.length !== 0) {
  //     setDisplayedGovernments([
  //       {
  //         id: 0,
  //         government: t("Choose a Governorate.."),
  //       },
  //       ...governments,
  //     ]);
  //   }
  // }, [governments]);

  // useEffect(() => {
  //   if (selectedGovernmentId != "0") {
  //     const selectedGovernemateObject = governments.filter(
  //       (government: Government) =>
  //         government.id == Number(selectedGovernmentId)
  //     )[0];
  //     setDisplayedBranches([
  //       {
  //         id: 0,
  //         name: t("Choose Branch.."),
  //       },
  //       ...selectedGovernemateObject.branches,
  //     ]);
  //   } else {
  //     setDisplayedBranches([]);
  //   }
  //   setSelectedBranchId("0");
  // }, [selectedGovernmentId]);

  useEffect(() => {
    if (phoneNumber.length > 0)
      setIsPhoneNumberEgyption(EgyptionNumbersRegex.test(phoneNumber));
    else setIsPhoneNumberEgyption(undefined);
    setIsNumberAlreadyApplied(false);
    setIsCheckBtnShown((val: boolean) => {
      if (!val) return true;
      else return val;
    });
    setIsSubmitShown((val: boolean) => {
      if (val) return false;
      else return val;
    });
  }, [phoneNumber]);
  useEffect(() => {
    if (props.amountOfLoans.length !== 0) {
      // setSelectedAmountOfLoanId(props.amountOfLoans[0].id);
    }
  }, [props.amountOfLoans]);

  return (
    <>
      <CommonModal
        isSuccess={isSentSucc}
        successText="Your Message Has been sent successfully"
        errorText="An Error Occured Please try again"
        show={isModalOpen}
        handleClose={setIsModalOpen}
      />
      <Form className="loan-request-form">
        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("Full name")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Control
            className={`${isFullNameValid === false ? "required-border" : ""}`}
            maxLength={250}
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            type="text"
          />
        </Form.Group>
        {isFullNameValid === false && (
          <div className="error-state mb-3">
            <img src={images.errorIcon} />
            <p>{t("Please enter valid full name")}</p>
          </div>
        )}
        {props.type === "mf" && (
          <div className="form-row">
            <Form.Group className="mb-3 my-col">
              <Form.Label className="font bold">
                {t("Amount of loan")}
                <span className="text-danger"> * </span>
              </Form.Label>
              <Form.Select
                value={selectedAmountOfLoanId}
                onChange={(e: any) => {
                  setSelectedAmountOfLoanId(e.target.value);
                }}
              >
                <option value={"-1"}>اختر المبلغ</option>
                {typesOfMontag.map(
                  (op: any) =>
                    op.Name !== "وسائل نقل خفيفة" && (
                      <option key={op.Id} value={op.Id}>
                        {op.Name}
                      </option>
                    )
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 my-col">
              <Form.Label className="font bold">
                {t("ActivityType")}
                <span className="text-danger"> * </span>
              </Form.Label>
              <Form.Select
                value={selectedProjectType}
                onChange={(e: any) => {
                  setSelectedProjectType(e.target.value);
                }}
              >
                <option>اختر نوع النشاط</option>
                {projectTypes.map((option: staticOptionsObject) => (
                  <option key={option.id} value={option.value}>
                    {option.description}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        )}
        {props.type === "sme" && (
          <>
          

        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("type of product")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Select
            onChange={(e: any) => {
              setProductType({
                id: e.target.value,
                name: e.target.value === 9 ? "برنامج تمويل عملاء مشروعات صغيرة جدا (اسلامي)" : "برنامج تمويل عملاء مشروعات صغيرة جدا ",
              });
            }}
          >
            <option value={"-1"}>{"اختر نوع المنتج"} </option>
            {productTypeList.map((op: any) => (
              <option key={op.id} value={op.id}>
                {op.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>


        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("choose product")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Select
            value={selectedProductId}
            onChange={(e: any) => {
              setSelectedProductId(e.target.value);
              
            }}
            disabled={productType.id === "0"}
          >
            
            <option value={"-1"}>{"اختر المنتج"} </option>
            {productsList.map((op: any) => (
              <option key={op.Id} value={op.Name}>
                {op.Name}
              </option>
            ))}
           
          </Form.Select>
        </Form.Group>
          </>
        )}
        {props.type === "lv" && (
          <>
            <Form.Group className="mb-3">
              <Form.Label className="font bold">
                {t("wish to finance")}
                <span className="text-danger"> * </span>
              </Form.Label>
              <Form.Select
                value={selectedAmountOfLoanId}
                onChange={(e: any) => {
                  setSelectedAmountOfLoanId(e.target.value);
                  console.log(e.target.value);
                }}
              >
                <option value={"0"}>ترغب في تمويل</option>
                {typesOfMontag.map(
                  (op: any) =>
                    op.Name === "وسائل نقل خفيفة" && (
                      <option key={op.Id} value={op.Id}>
                        {op.Name}
                      </option>
                    )
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 my-col">
              <Form.Label className="font bold">
                {t("Product Type")}
                <span className="text-danger"> * </span>
              </Form.Label>
              <Form.Select
                onChange={(e: any) => {
                  setTransportProduct({
                    id: e.target.value,
                    name: e.target.value,
                  });
                }}
              >
                <option value={"-1"}> نوع المنتج</option>

                {transportationProductType.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        )}

        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("Governorate in which the activity is located")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Select
            value={city}
            onChange={(e: any) => {
              setCity(e.target.value);
            }}
          >
            <option value={"-1"}>{"إختر المحافظة"} </option>
            {govermentsList.map((op: any) => (
              <option key={op.Id} value={op.Id}>
                {op.Name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("Nearest branch To the workplace")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Select
            value={selectedBranchId}
            onChange={(e: any) => {
              setSelectedBranchId(e.target.value);
            }}
            disabled={city === "0"}
          >
            <option> اختر الفرع</option>

            {branchesList.map((op: any) => (
              <option key={op.Id} value={op.Id}>
                {op.Name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="font bold">
            {t("Phone number")}
            <span className="text-danger"> * </span>
          </Form.Label>
          <Form.Control
            className="text-start"
            maxLength={11}
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            type="tel"
          />
          {isPhoneNumberEgyption == false && (
            <div className="error-state">
              <img src={images.errorIcon} />
              <p>يجب أن يكون رقم الهاتف 11 رقمًا باللغة الإنجليزية</p>
            </div>
          )}
        </Form.Group>

        {/* {isCheckBtnShown && (
          <div className="d-flex align-items-center ">
            <CommonOrangeButton
              isSending={isSendingPhoneNumber}
              disabled={!!}
              onClick={checkPhoneNumber}
            >
              {t("Check")}
            </CommonOrangeButton>
            <>
              {isNumberAlreadyApplied && (
                <div className="error-state">
                  <img src={images.errorIcon} />
                  <p>{t("This phone number is already registerd")}</p>
                </div>
              )}
            </>
          </div>
        )} */}
        {/* {isSubmitShown && ( */}
        <>
          {props.type === "mf" && (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="font bold">
                  {t(
                    "Do you have an existing business that has been in existence for at least a year?"
                  )}
                  <span className="text-danger"> * </span>
                </Form.Label>
                <Form.Check
                  checked={hasWorkingProject}
                  onChange={() => {
                    setHasWorkingProject(true);
                  }}
                  inline
                  label={t("Yes")}
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-1`}
                />
                <Form.Check
                  checked={!hasWorkingProject}
                  onChange={() => {
                    setHasWorkingProject(false);
                  }}
                  inline
                  label={t("No")}
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-2`}
                />
              </Form.Group>
              <p className="font light text-muted mb-2">
                {t(
                  "Existing income-generating projects are financed and have been established for at least one year"
                )}
              </p>
            </>
          )}
          {props.type === "sme" && (
            <>
              
              <p className="font light text-muted mb-2">
                {t(
                  "If the company's sales are less than one million pounds, you can apply for microfinance"
                )}
              </p>
              <Form.Group className="mb-3">
                <Form.Label className="font bold">
                  {t(
                    "Do you have official documents for the activity? (commercial register / tax card) ?"
                  )}
                  <span className="text-danger"> * </span>
                </Form.Label>
                <Form.Check
                  checked={hasOfficialDocuments}
                  onChange={() => {
                    setHasOfficialDocuments(true);
                  }}
                  inline
                  label={t("Yes")}
                  name="group3"
                  type={"radio"}
                  id={`inline-radio-1`}
                />
                <Form.Check
                  checked={!hasOfficialDocuments}
                  onChange={() => {
                    setHasOfficialDocuments(false);
                  }}
                  inline
                  label={t("No")}
                  name="group3"
                  type={"radio"}
                  id={`inline-radio-2`}
                />
              </Form.Group>
            </>
          )}
          <CommonOrangeButton
            isSending={isSending}
            disabled={isSendDisabled()}
            onClick={sendLoanReq}
          >
            {t(loanRequestContent.formSubmitBtn)}
          </CommonOrangeButton>
        </>
        {/* )} */}
      </Form>
    </>
  );
};

export default LoanRequestForm;
