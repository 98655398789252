import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { sec5Content } from "../../../content/services";
import { useTranslation } from "react-i18next";

const ReaderRow: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row className="bright-blue">
      <Col className="right-space">
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <h2 className="font bold orangeBg">{t(sec5Content.title)}</h2>
        </div>
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <p className="font light">{t(sec5Content.details)}</p>
        </div>
      </Col>
      <Col className="left-height reader-container">
        <ReaderSection
          title={sec5Content.itemsList[1].content}
          icon={sec5Content.itemsList[0].content}
        />
      </Col>
    </Row>
  );
};

const ReaderSection: React.FC<{ title: string; icon: string }> = ({
  title,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="left-row ">
        <div
          data-aos="zoom-in"
          data-aos-duration="400"
          className="circle-icon-container reader aos-init"
        >
          <Image src={icon} width={830} height={530} />
        </div>
      </div>
      <div className="left-row ">
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="left-text reader aos-init"
        >
          <p className="font light s23">{t(title)}</p>
        </div>
      </div>
    </>
  );
};

export default ReaderRow;
