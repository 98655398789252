import React, { FC } from "react";
import "../../../style/components/_loan-request-step-one.scss";
import { on } from "events";
type TermsCheckboxProps = {
  label: string; // The main label text
  linkText: string; // The clickable link text

  checked: boolean; // Checkbox state
  onChange: (checked: boolean) => void; // Callback for checkbox state change
  labelStyle?: React.CSSProperties; // Additional styles for the label
  linkStyle?: React.CSSProperties; // Additional styles for the link
  checkboxStyle?: React.CSSProperties; // Additional styles for the checkbox
  onLinkClick: React.Dispatch<React.SetStateAction<boolean>>;
};

const TermsCheckbox: FC<TermsCheckboxProps> = ({
  label,
  linkText,

  checked,
  onChange,
  labelStyle,
  linkStyle,
  checkboxStyle,
  onLinkClick,
}) => {
  return (
    <div className="terms-container">
      {/* Checkbox */}
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        // style={{
        //   width: "18px",
        //   height: "18px",
        //   cursor: "pointer",
        //   ...checkboxStyle,
        // }}
      />
      {/* Label and Link */}
      <span
        style={{
          fontSize: "18px",
          color: "#333",
          ...labelStyle,
          fontFamily: "Cairo",
        }}
      >
        {label}{" "}
        <a
          onClick={() => onLinkClick(true)}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            fontSize: "20px",
            color: "#02A9BF",
            fontWeight: "bold",
            fontFamily: "Cairo",
            ...linkStyle,
            cursor: "pointer",
          }}
        >
          {linkText}
        </a>
      </span>
    </div>
  );
};

export default TermsCheckbox;
