import constants from "../constants";

let initialState = {
  lang: "ar",
  width: window.innerWidth,
};

const settingsReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case constants.SET_LANG:
      return { ...state, lang: action.payload };
    case constants.SET_WIDTH:
      return { ...state, width: action.payload };

    default:
      return state;
  }
};
export default settingsReducer;
