import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NewsItem } from "types";

interface IProps {
  news: NewsItem;
}

const NewsBanner: React.FC<IProps> = ({ news }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (news.external_link) {
      window.location.replace(news.external_link);
    } else {
      navigate(`/news/featured-news`);
    }
  };

  return (
    <Row className="news-header__section">
      <Col xs={12} lg={7} className="news-header__header">
        <h5 className="font text-white light">{news?.date}</h5>
        <h2 className="font text-white bold">{news?.header}</h2>
        <p className="font text-white light mb-3">{news?.paragraph}</p>
        <button className="amnmf-btn-outlined m-1" onClick={handleReadMore}>
          {t("read more")}
        </button>
      </Col>
      <Col xs={12} lg={5} className={"banner-image-container"}>
        <img src={news?.image} className={"banner-image"} />
      </Col>
    </Row>
  );
};

export default NewsBanner;
