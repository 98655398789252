import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import images from "assets/images";

const CareersBanner: React.FC = (props) => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <section className="careers-form-banner__section">
      {params.job === "join-us" && (
        <div className="joinus-header w-100 p-3">
          <div className="banner-image-container mx-5">
            <img src={images.joinus} className={"banner-image"} />
          </div>
          <div className="banner-text-container">
            <h2 className="font text-white bold">{t("Join Aman Team")}</h2>
            <p className="font text-white light mb-5 ">
              {t("Be one of Aman microfinance team now")}
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default CareersBanner;
