import images from "assets/images";
import React, { FC, ReactChild, useState } from "react";
import UploadIcon from "./UploadIcon";
import EditIcon from "./EditIcon";

type FormBaseButtonProps = {
  title: string; // The button text
  onClick: () => void; // Function to call when button is clicked
  icon?: "edit" | "upload"; // Icon displayed on the right side
  backgroundColor?: string; // Background color for the button
  textColor?: string; // Text color
  style?: React.CSSProperties; // Additional styles for the button container
  textStyle?: React.CSSProperties; // Additional styles for the button text
};

const FormBaseButton: FC<FormBaseButtonProps> = ({
  title,
  onClick,
  icon,
  backgroundColor = "#02A9BF", // Default background color
  textColor = "#FFFFFF", // Default text color
  style,
  textStyle,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <button
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px", // Spacing between text and icon

        backgroundColor: isHovered ? "#fff" : backgroundColor,
        color: isHovered ? backgroundColor : "#fff",
        border: "none",
        borderRadius: "8px", // Rounded corners
        // fontSize: "16px",

        fontWeight: "bold",
        cursor: "pointer",
        width: "10.75rem",
        height: "3rem",

        // minWidth: "max-content",
        boxShadow:
          "inset 2px 4px 4px rgba(0, 0, 0, 0.25), inset -2px -4px 4px rgba(0, 0, 0, 0.25)",
        ...style, // Merges additional styles
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon === "upload" ? (
        <UploadIcon color={isHovered ? backgroundColor : "#fff"} />
      ) : (
        <EditIcon color={isHovered ? backgroundColor : "#fff"} />
      )}
      {/* Renders the icon if provided */}
      <p
        style={{
          fontFamily: "Cairo",
          fontSize: "16px",
        }}
      >
        {title}
      </p>
    </button>
  );
};

export default FormBaseButton;
