import React from "react";
import { Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { loanRequestCarousel } from "content/loan_request";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";

const LoanRequestCarousel: React.FC = (props) => {
  const { lang, width } = useSelector(
    (state: RootState) => state.settingsReducer
  );

  return (
    <OwlCarousel
      items={width < 991.98 ? 1 : 3}
      className="owl-theme"
      dots={false}
      rewind
      loop
      autoplay
      rtl={lang === "en" ? false : true}
      autoplayTimeout={2000}
    >
      {loanRequestCarousel.map((path, i) => (
        <div key={i} className="item">
          <Image
            className="w-100"
            src={path}
            alt="Second slide"
            width="600"
            height="550"
          />
        </div>
      ))}
    </OwlCarousel>
  );
};

export default LoanRequestCarousel;
