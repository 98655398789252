import { loanRequestContent, loanRequestFormSteps } from "content/loan_request";
import { t } from "i18next";
import React, { FC, useState } from "react";
import "../../../style/components/_loan-request-step-one.scss";
import images from "assets/images";
import CommonOrangeButton from "components/Button";
import FormBaseButton from "./FormBaseButton";
import TermsCheckbox from "./TermsCheckbox";
import ReactLoading from "react-loading";
export interface StepOneProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFrontImage: string | null;
  setSelectedFrontImage: React.Dispatch<React.SetStateAction<string | null>>;
  selectedBackImage: string | null;
  setSelectedBackImage: React.Dispatch<React.SetStateAction<string | null>>;
  setIsSubModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  goToNextStep: () => void;
  subModalContent: "success" | "sorry" | "error" | "terms" | null;
  setSubModalContent: React.Dispatch<
    React.SetStateAction<"success" | "sorry" | "error" | "terms" | null>
  >;
  handleOCR: (e: any) => Promise<any>;
}
const StepOne: FC<StepOneProps> = ({
  checked,
  setChecked,
  selectedFrontImage,
  setSelectedFrontImage,
  selectedBackImage,
  setSelectedBackImage,
  setIsSubModalVisible,
  goToNextStep,
  subModalContent,
  setSubModalContent,
  handleOCR,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const onSubmit = async (event: React.FormEvent) => {
    try {
      setLoading(true);
      const res = await handleOCR(event);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      alert(error.data.message);
    }
  };
  return (
    <div className="step-one">
      <h1
        style={{ fontFamily: "Cairo", fontSize: "25px", fontWeight: "700" }}
        className="font bold carousel-header"
      >
        {t(loanRequestFormSteps.stepOneTitle)}
        <span
          className="loan-request-form-container-inner-hours"
          style={{ fontFamily: "Cairo", fontSize: "25px", fontWeight: "700" }}
        >
          {" "}
          {t(loanRequestFormSteps.your_funding)}
        </span>
      </h1>
      <div className="navigation-sign">
        <div className="from">
          <p>1</p>
          <img src={images.icon_upload_id} />
          <p
            style={{
              fontFamily: "Tajawal",
              fontSize: "20px",
              color: "#35363D",
            }}
          >
            {t(loanRequestFormSteps.stepOneDescription)}
          </p>
        </div>
        <img src={images.icon_directed_arrows} width={"40%"} height={"auto"} />
        <div className="to">
          <p>2</p>
          <img src={images.icon_complete_data} />
          <p
            style={{
              fontFamily: "Tajawal",
              fontSize: "20px",
              color: "#35363D",
            }}
          >
            {t(loanRequestFormSteps.stepTwoDescription)}
          </p>
        </div>
      </div>
      <div className="form">
        <div className="subContainer">
          <div className="right-section">
            <p style={{ fontFamily: "Tajawal", fontSize: "18px" }}>
              وجه البطاقة الامامى
            </p>
            <FormBaseButton
              title={frontImage ? "تغيير الصورة" : "رفع صورة"}
              onClick={() =>
                document.getElementById("imageInputFront")?.click()
              }
              icon={frontImage ? "edit" : "upload"}
              backgroundColor={frontImage ? "#E9581E" : "#02A9BF"}
              textColor="#FFFFFF"
            />
            <input
              type="file"
              id="imageInputFront"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const result = reader.result as string;
                    setFrontImage(result);
                    if (result) {
                      // Remove the `data:image/jpeg;base64,` prefix
                      const base64String = result.replace(
                        /^data:image\/\w+;base64,/,
                        ""
                      );
                      setSelectedFrontImage(base64String);
                    } else {
                      console.error("FileReader result is null.");
                    }
                    // setSelectedBackImage(reader.result as string);
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
          </div>

          <div className="id-image">
            <img
              src={frontImage ? frontImage : images.idFrontFace}
              style={{
                width: "100%",
                height: "95%",
                paddingBlock: "1rem",
                paddingInlineEnd: "1rem",
                maxHeight: "10rem",
                maxWidth: "15.625rem",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="right-section">
            <p style={{ fontFamily: "Tajawal", fontSize: "18px" }}>
              وجه البطاقة الخلفى
            </p>
            <FormBaseButton
              title={backImage ? "تغيير الصورة" : "رفع صورة"}
              onClick={() => document.getElementById("imageInput")?.click()}
              icon={backImage ? "edit" : "upload"}
              backgroundColor={backImage ? "#E9581E" : "#02A9BF"}
              textColor="#FFFFFF"
            />
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const result = reader.result as string;
                    setBackImage(result);
                    if (result) {
                      // Remove the `data:image/jpeg;base64,` prefix
                      const base64String = result.replace(
                        /^data:image\/\w+;base64,/,
                        ""
                      );
                      setSelectedBackImage(base64String);
                    } else {
                      console.error("FileReader result is null.");
                    }
                    // setSelectedBackImage(reader.result as string);
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
          </div>

          <div className="id-image">
            <img
              src={backImage ? backImage : images.idBackFace}
              style={{
                width: "100%",
                height: "95%",
                paddingBlock: "1rem",
                paddingInlineEnd: "1rem",
                maxHeight: "10rem",
                maxWidth: "15.625rem",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
      </div>

      <TermsCheckbox
        label="اقر باطلاعى و قبولى"
        checked={checked}
        onChange={setChecked}
        linkText="الشروط و الاحكام"
        onLinkClick={() => {
          setIsSubModalVisible(true);
          setSubModalContent("terms");
        }}
      />
      <div className="form-modal-footer">
        <button
          className="form-modal-close-button"
          onClick={(event) => onSubmit(event)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={
            frontImage !== null && backImage !== null && checked !== false
              ? false
              : true
          }
        >
          {loading ? (
            <ReactLoading
              className="loading-element"
              type="balls"
              color={isHovered ? "#2FA3B5" : "#fff"}
              height={20}
              width={25}
            />
          ) : (
            <p style={{ fontFamily: "Tajawal", fontSize: "20px" }}>التالى</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default StepOne;
