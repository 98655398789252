import React from "react";
import { successStoriesContent } from "content/landing";
import SuccessStoriesCarousel from "../components/successStoriesCarousel";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  loading: boolean;
}

const Section6: React.FC<IProps> = ({ loading }) => {
  const { t } = useTranslation();
  return (
    <section className="amanmf__success-stories">
      <div className="amanmf__success-stories__text">
        <h3 className="f-mxl font bold mb-5">
          {t(successStoriesContent.title)}
        </h3>
        <p className="f-sm font light mb-5">
          {t(successStoriesContent.subText)}
        </p>
        <NavLink
          to={"/success-stories"}
          className="amnmf-btn-filled m-0 font bold"
        >
          {t(successStoriesContent.btnText)}
        </NavLink>
      </div>
      <SuccessStoriesCarousel loading={loading} />
    </section>
  );
};
export default Section6;
