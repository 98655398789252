import i18n from "localization/localization";
import { Dispatch } from "react";
import constants from "../constants";

type Lang = "ar" | "en";

export const setLang = (lang: Lang) => {
  return {
    type: constants.SET_LANG,
    payload: lang,
  };
};

export const setWidth = (width: number) => {
  return {
    type: constants.SET_WIDTH,
    payload: width,
  };
};

export const initWidthListener = () => (dispatch: Dispatch<any>) => {
  window.addEventListener("resize", () => {
    dispatch(setWidth(window.innerWidth));
  });
};

export const removeWidthListener = () => (dispatch: Dispatch<any>) => {
  window.removeEventListener("resize", () => {
    dispatch(setWidth(window.innerWidth));
  });
};

export const checkAndSetPrevLang = () => (dispatch: Dispatch<any>) => {
  const initLang = (lang: Lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLang(lang));
    document.querySelector("html")!.setAttribute("lang", lang);
    document
      .querySelector("html")!
      .setAttribute("dir", lang == "ar" ? "rtl" : "ltr");
  };
  const lang = localStorage.getItem("lang") as Lang;
  if (lang) {
    initLang(lang);
  } else {
    initLang("ar");
  }
};
