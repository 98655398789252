import { t } from "i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import images from "assets/images";
import { RootState } from "store/configStore";

const NewsDetailsMain: React.FC = () => {
  const params = useParams();

  const { newsDetails, featuredNews } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const [article, setArticle] = useState(newsDetails);

  const handleWhatsAppShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${window.location.href}`,
      "popup",
      "width=800,height=600"
    );
  };

  useEffect(() => {
    params.newsId == "featured-news"
      ? setArticle(featuredNews)
      : setArticle(newsDetails);
  }, [params.newsId, newsDetails, featuredNews]);

  return (
    <Row className="article-container">
      <Col xs={12} lg={8} className={"article-first-column"}>
        <p className="font light">{article?.date}</p>
        <h2 className="font bold">{article?.header}</h2>
        <p className="font light">{article?.paragraph}</p>
      </Col>
      <Col xs={12} lg={4} className={"article-second-column"}>
        <img
          src={article?.image}
          alt={"article-image"}
          className={"article-image"}
        />
        <p className="font bold share-article-label">{t("Share Article")}</p>
        <div className="share-article-icons">
          <button
            onClick={handleWhatsAppShare}
            data-action="share/whatsapp/share"
          >
            <img
              src={images.whatsappBlueIcon}
              alt={"whatsApp icon"}
              className={"share-article-icon"}
            />
          </button>
          <a
            href={`http://www.twitter.com/share?url=${window.location.href}`}
            target="_blank"
          >
            <img
              src={images.twitterBlueIcon}
              alt={"twitter icon"}
              className={"share-article-icon"}
            />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
          >
            <img
              src={images.facebookBlueIcon}
              alt={"facebook icon"}
              className={"share-article-icon"}
            />
          </a>
          <a
            href={`https://www.linkedin.com/cws/share?url=${window.location.href}`}
            target="_blank"
          >
            <img
              src={images.linkedinBlueIcon}
              alt={"linkedin icon"}
              className={"share-article-icon"}
            />
          </a>
        </div>
      </Col>
    </Row>
  );
};
export default NewsDetailsMain;
