import images from "../assets/images";

export const loanRequestContent = {
  modalTitle: `Terms and Guidelines for Getting Started with Amana`,
  header: `General conditions and features to be available in the client`,
  header2: `Important Alert`,
  header3: `The ability to pay through a network of Aman branches, electronic payment methods and at the nearest place to you.`,
  btnText: `Have questions? Read the answers to the most frequently asked questions about Amanah financing`,
  btnText2: `Apply for financing now`,
  carouselHeader: `Loan request application form`,
  formSubmitBtn: `Apply request`,
  getFund: `Get funding for your project within `,
  hours: `48 hours`,
  requestYourFund: `Request your fund`,
  ContactUs: `Contact us`,
};
export const loanRequestFormSteps = {
  stepOneTitle: `Only 2 steps separating you from`,
  your_funding: `your funding`,
  stepOneDescription: "upload your verification ID ",
  stepTwoDescription: "complete your data",
};
export const loanRequestRulesSME = [
  `A recent and valid commercial register.`,
  `The tax card is valid.`,
  `Customer and guarantor card.`,
  `Documents of ownership or lease of the headquarters and documents of branches or warehouses.`,
  `The company's articles of incorporation, amendment contracts, or company newspaper.`,
  `The last 3 budgets.`,
  `The credentials of the chartered accountant`,
  `Bank account statement.`,
  `A statement of the tax and insurance position`,
  `The latest social security payment receipt.`,
  `Activity license.`,
  `Basic data certificate from the tax authority.`,
  `Supply orders, valid contracts, or withdrawal statements`,
  `bankruptcy and protesto`,
  `Recruitment certificate for clients under 30 years old`,
  `Combined statement and related parties`,
];

export const loanRequestRulesVSE = [
  `بطاقة الرقم القومى للعميل والضمان.`,
  `شهادة الموقف من التجنيد للعميل ( للعملاء الاقل من 30 عام ) `,
  `رخصة مزاولة النشاط "ان وجد" ,فيما عدا الانشطة التى تستوجب رخصة نشاط ( المقاولات، المراكز الطبية و معامل التحاليل و الأشعة، الأنشطة الصناعية، الأدوية والصيدليات، المطاعم والكافيهات، الأغذية التي يتم تصنيعها أو تداولها باليد والتي تستلزم تراخيص وشهادات صحية ، و أي نشاط يقوم بالبيع للجمهور مباشرة)`,
  `مستخرج حديث من السجل التجارى لم يمر عليه 3 اشهر.`,
  `بطاقة ضريبية سارية.`,
  `عقد الشركة وملخصة المشهر عنه. "في حالة الشركات" `,
  `عقد ايجار ساري مثبت التاريخ يغطي نصف مدة التمويل `,
  `ايصال مرافق حديث للنشاط / و سكن العميل و الضمان `,
  `وثيقة بيانات اساسية للنشاط من مصلحة الضرائب باقرار اخر سنة مالية منتهية او اقرار ضريبي لاخر سنه مالية.`,
  `يترواح سن العميل بين 21- 63 عام عند منح التمويل.`,
  `ان يكون النشاط قائم مستندياً (سجل تجارى او بطاقة ضريبية) لمدة لا تقل عن سنة قبل تاريخ منح التمويل.`,
];

export const loanRequestRules = [
  `To be of Egyptian nationality.`,
  `The age ranges from 21 to 63 years.`,
  `That the client has an existing income-generating activity that satisfies the payment of the finance amount and its charges.`,
  `That the customer enjoys a good reputation through the prepared credit inquiry and the field inquiry through the place of residence and the place where the activity is carried out.`,
  `To have a valid National Number Card.`,
  `Not to be a guarantor for one of the company's existing clients.`,
  `To have a guarantor who has a good reputation from one of his first-degree relatives (father - mother - wife - son - daughter).In the event that a first-degree relative is not available, the guarantor is a person with a fixed income (employee or pensioner).`,
  `The client must provide evidence of his place of business and his permanent residence.`,
];

export const importantNotes = [
  `Be sure to get the payment card at the time of exchange and a receipt indicating the payment when paying cash inside our branches.`,
  `Be sure to deal with company employees only.`,
  "Applying for funding is free.",
];

export const loanRequestCarousel = [
  images.carousel0,
  images.carousel1,
  images.carousel2,
  images.carousel3,
  images.carousel4,
  images.carousel5,
  images.carousel6,
  images.carousel7,
  images.carousel8,
  images.carousel9,
  //   images.carousel10,
];
