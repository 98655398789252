import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Image, FormControl, InputGroup, Spinner } from "react-bootstrap";
import images from "../../../assets/images";
import { baseUrl } from "netwrok/baseUrl";
import endPoints from "netwrok/endPoints";
import axios from "axios";

interface Props {
  installment?: any;
  national?: string;
  getVodafoneIframe?: Function | undefined;
}

const PayInstallment: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  // PARAMS
  const { installment, getVodafoneIframe , national} = props;

  // LOCAL CONSTANT
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  // METHDOS
  const getMonth = (date: string) => {
    return Intl.DateTimeFormat("ar-EG", { month: "long" }).format(new Date(date));
  };
  const getDate = (date: string) => {
    return Intl.DateTimeFormat("ar-EG", { year: "numeric", month: "long" }).format(new Date(date));
  };
  const handlePhoneNumber = (e: any) => {
    console.log("PHONE NUMBER:", e.target.value);
    setPhoneNumber(e.target.value);
  };

  const API_payInstallment = (national_id:any) => {
    setLoading(true);
    axios({
      url: `https://mf.marketing-amansuperapp.online/api${endPoints.vodafone.pay}`,
      method: "POST",
      data: {
        national_id: national_id,
        mobile_number: phoneNumber,
      },
    }).then(
      (res) => {
        console.log("GET VODAFONR IFRAME SUCCESS: ", res);
        setLoading(false);
        if (getVodafoneIframe) getVodafoneIframe(res.data);
      },
      (err) => {
        console.log("GET VODAFONR IFRAME FAILED: ", err);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <div className="installment-info">
        <div className="installment-date">
          <h5 className="font bold">{`${t("installment")} ${getMonth(installment.dueDate)}`}</h5>
          <h6 className="font bold">{getDate(installment.dueDate)}</h6>
        </div>
        <div className="installment-user">
          <Image src={images.payInstallment} alt="" />
          <div className="user-info">
            <div className="user-id">
              <h6 className="font bold">{t("National ID")}</h6>
              <p className="font light">{national}</p>
            </div>
            <div className="user-name">
              <h6 className="font bold">{t("رقم القرض")}</h6>
              <p className="font light">{installment.accountNumber}</p>
            </div>
            <div className="user-name">
              <h6 className="font bold">{t("نوع القرض")}</h6>
              <p className="font light">{installment.description}</p>
            </div>
          </div>
        </div>
        <div className="installment-amount">
          <h6 className="font bold">{t("Amount")}</h6>
          <div className="installment-value-box">
            <p className="installment-value font bold">{installment.totalAmount}</p>
            <p className="installment-currency font light">{t("EGP")}</p>
          </div>
        </div>
      </div>
      <InputGroup>
        {/* <Image src={images.phoneIcon} className="phone-icon" alt="" /> */}
        <FormControl
          placeholder={t("Vodafone cash wallet phone number")}
          value={phoneNumber}
          aria-label="phone number"
          aria-describedby="basic-addon1"
          onChange={handlePhoneNumber}
        />
        <Button className="amnmf-btn --filled" onClick={()=>API_payInstallment(national)} disabled={phoneNumber.length < 11}>
          {loading ? <Spinner animation="border" size="sm" /> : <p>{t("Pay Now")}</p>}
        </Button>
      </InputGroup>
    </>
  );
};

export default PayInstallment;
