import React from "react";
import { Row, Col } from "react-bootstrap";

const EmptyRow: React.FC<{ className: string }> = ({ className = "" }) => {
  return (
    <Row className="r-h10 ">
      <Col className="right-space"></Col>
      <Col className={`${className}`}></Col>
    </Row>
  );
};

export default EmptyRow;
