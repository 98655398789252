import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
const Section5: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="aboutAman__vision__section d-flex" id={"vision"}>
      <div className="col-8 pt-5 pb-4">
        <h2 className="font text-white bold">{t("Vision")}</h2>
        <p className="font light text-white">
          {t(
            "Enhancing and spreading financial inclusion and leadership in providing financing to business owners and micro-enterprises with appropriate speed and flexibility, and using the best smart technological means"
          )}
        </p>
        <h2 className="font text-white bold">{t("The message")}</h2>
        <p className="font light text-white">
          {t(
            "To be (Aman) the market leader in using the best programs and smart technological solutions to provide all non-banking financial services and products to the broad sector of craftsmen and micro-business owners, with speed and flexibility appropriate to the nature of dealing with clients of this sector, and through branches spread in all governorates of the Republic ."
          )}
        </p>
        <h2 className="font text-white bold">{t("Objectives")}</h2>
        <p className="font light text-white">
          {t(
            "Aman Microfinance Company aims to provide financing to business owners and small and micro enterprises on easy terms and procedures commensurate with the nature of the activities practiced by these groups within the different regions and governorates in which they practice their activities and in accordance with the Small and Micro Enterprises Regulations Law and the rules and standards of practice issued by the Authority General Financial Supervisory Authority to regulate the activity."
          )}
        </p>
      </div>
      <div className="aboutAman__vision__img col-4">
        <img
          src={images.doubleArrow}
          alt="Double Arrow"
          className="w-100 arrow__img"
        />
      </div>
    </section>
  );
};
export default Section5;
