import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { placeMarkerOnMap, removeAllMarkers } from "../../../helper/mapModule";
import { Government, Branch } from "../../../types/index";

const BranchesList: React.FC = (props) => {
  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const changeMap = (e: any) => {
    removeAllMarkers();
    let currBranch: Branch | any;
    governments.find((gov: Government) => {
      currBranch = gov.branches.find(
        (b: Branch) => `${b.latitude}:${b.longitude}` === e.currentTarget.value
      );
      return currBranch;
    });
    placeMarkerOnMap(currBranch);
  };

  const tempContent: JSX.Element[] = [];
  if (governments !== []) {
    governments.forEach((gov: Government, index: any) => {
      let govHeader = (
        <option
          className="sel-branch-head to-animate"
          disabled
          key={`government-${index}`}
        >
          {gov.name}
        </option>
      );
      tempContent.push(govHeader);
      gov.branches.forEach((b: Branch) => {
        const branchItem = (

          <option
            className="sel-branch-item to-animate"
            value={`${b.latitude}:${b.longitude}`}
            key={b.address}
          >
            {b.name + " - "+ b.address}
          </option>
        );
        tempContent.push(branchItem);
      });
    });
  }

  return (
    <select
      multiple
      className="form-control branches-select branches-box"
      id="branches-sel"
      onChange={changeMap}
    >
      {tempContent}
    </select>
  );
};

export default BranchesList;
