interface Props {
  text: string;
  count: number;
}
const HeaderWithLine: React.FC<Props> = ({ text, count }) => {
  let contentSp = text.split(" ");
  let highlighted = [];
  for (let i = 0; i < count; i++) {
    let word = contentSp.shift();
    highlighted.push(word);
  }

  return (
    <h2 className="font bold">
      <span className="bg-line">{`${highlighted.join(" ")} `}</span>
      {contentSp.join(" ")}
    </h2>
  );
};

export default HeaderWithLine;
