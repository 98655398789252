import images from "../assets/images";

export const imgs = [
  {
    id: 1,
    src: images.calendar,
  },
  {
    id: 2,
    src: images.moneyTime,
  },
  {
    id: 3,
    src: images.money,
  },
  {
    id: 4,
    src: images.paymentMetthods,
  },
];
export const faqs = [
  {
    id: 1,
    category: "Funding Period",
    Q_As: [
      {
        id: 1,
        question: "Funding Period",
        answer: "1 week - 2 weeks - 1 month - 2 months - 3 months",
      },
    ],
  },
  {
    id: 2,
    category: "Payment Periodicity",
    Q_As: [
      {
        id: 1,
        question: "Payment Periodicity",
        answer: "On a weekly basis - two weeks - monthly",
      },
    ],
  },
  {
    id: 3,
    category: "Funding Disbursement Methods",
    Q_As: [
      {
        id: 1,
        question: "Funding Disbursement Methods",
        answer:
          "Through Aman branches for financial services - Aman branches for microfinance",
        answer2:
          "In the event that the financing exceeds the amount of 2000 pounds, the financing will be disbursed to the customer using one of the non-cash disbursement methods",
      },
    ],
  },
  {
    id: 4,
    category: "Payment Methods",
    Q_As: [
      {
        id: 1,
        question: "Payment Methods",
        answer:
          "Through Aman Financial Services branches - Aman payment machines - Jumia Pay - Aman application - Banque du Caire wallet",
      },
    ],
  },
];
