const ocrBaseUrl = process.env.REACT_APP_OCR_API_URL;
console.log("ocrBaseUrl", ocrBaseUrl);
export default {
  company: {
    branches: "/company/governemnts",
    news: "/company/news",
    userProtectionGuide: "/company/user_protection_guide",
    about: "/company/about",
    policy: "/company/policy",
  },
  application: {
    onGroundLoanReq: "/applications/post-general-loan-request",
    onGroundLoanReqMF: "/applications/post-loan-request-micro-finance",
    onVseLoanReq: "/applications/vse-loan-request",
    onlineLoanReq: "/applications/post-online-loan-request",
    contactUs: "/applications/post-contact-us",
    checkPhoneNumberGeneral: "/applications/general-loan-request/",
    checkPhoneNumberOnline: "/applications/general-online-loan-request/",
    getLeadMarket: "​/applications​/get-lead-requests_national-id​/",
  },
  content: {
    amountOfLoansMf: "/content/amount-of-loans-mf",
    amountOfLoansSme: "/content/amount-of-loans-sme",
    amountOfLoansLv: "/content/amount-of-loans-lv",
    FAQ: "/content/FAQ",
    annualReports: "/content/annual-reports",
    landingPage: "/content/landing-page",
    careers: "/careers/",
    successStories: "/content/success-stories",
    careersApply: "/careers/apply/",
    cons: "/content/constants",
    ocr: `${ocrBaseUrl}Verify/OCR_Prepaid`,

    promocode:
      "https://mf.amanmicrofinance.com/api/promo-code/check_voucher_code/",
  },
  vodafone: {
    getInstallment: "/vodafone/getInstallments",
    pay: "/vodafone/pay",
  },
};
