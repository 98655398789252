import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const Loader: React.FC = () => {
  return (
    <div className="text-center loading-page">
      <Spinner animation="border" variant="info" />
    </div>
  );
};
export default Loader;
