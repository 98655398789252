import React from "react";
import { Image } from "react-bootstrap";
import images from "../../../assets/images";
import { heroHeaderIcons } from "../../../content/landing";
import HeroHeaderIcon from "./HeroHeaderIcon";
import { useTranslation } from "react-i18next";

interface Props {
  text1: string;
  text2: string;
  type: string;
  icon: any;
  bgClass: string;
}

const HeroStatBox: React.FC<{ data: Props }> = ({ data }) => {
  const { t } = useTranslation();
  if (data.type === "phone") {
    const contactIcons = heroHeaderIcons.map((el, i) => (
      <HeroHeaderIcon key={i} path={el.path} icon={el.icon} />
    ));
    return (
      <div className={`col-6 area-title__stats__item ${data.bgClass}`}>
        <h3 className="f-sm font bold">{t(data.text1)}</h3>
        <div className="d-flex align-items-center mt-3">
          <h4 className="f-xl font bold ml-2">{t(data.text2)}</h4>
          <a href="tel:19910">
            <Image src={images.phone} width="43" height="43" className="m-1" />
          </a>
        </div>
        <div className="d-flex mt-3">{contactIcons}</div>
      </div>
    );
  }

  return (
    <div className={`col-3 area-title__stats__item ${data.bgClass}`}>
      <Image src={data.icon} />
      <h4 className="f-xl font bold pt-3">{t(data.text1)}</h4>
      <h3 className="f-sm font bold">{t(data.text2)}</h3>
    </div>
  );
};

export default HeroStatBox;
