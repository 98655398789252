import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface props {
  content: string;
  inputType: string;
  inputLength?: number;
  value?: string;
  change?: (e: any) => void;
  blur?: () => void;
}
const FormInput: React.FC<props> = (props) => {
  const { t } = useTranslation();
  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column className="font light request__form__label">
        {t(props.content)}
        <span className="text-danger">*</span>
      </Form.Label>
      <Col lg={8} className="request__form__input">
        <Form.Control
          required
          type={props.inputType}
          maxLength={props.inputLength}
          name={props.inputType}
          value={props.value}
          onChange={props.change}
          onBlur={props.blur}
        />
      </Col>
    </Form.Group>
  );
};
export default FormInput;
