import React from "react";

import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import images from "../../../assets/images";
import StoryCarouselItem from "../components/StoryCarouselItem";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { SuccessStoryCarouselItem } from "../../../types/index";
import Loader from "components/Loader";

interface IProps {
  loading: boolean;
}

const SuccessStoriesCarousel: React.FC<IProps> = ({ loading }) => {
  let prevActiveItem: HTMLDivElement;
  const { landingPage } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const carouselChangedHandler = (ev: any) => {
    const carouselItems = ev.currentTarget?.children[0]?.children[0]?.children;
    const activeIndex = ev.item.index;
    const activeItem = carouselItems ? carouselItems[activeIndex] : null;
    const currContent = activeItem?.children[0];
    currContent?.classList.add("animate");
    if (ev.property.name !== "settings")
      prevActiveItem?.classList.remove("animate");

    prevActiveItem = activeItem?.children[0];
  };

  const content = landingPage.feat_news?.map((el: SuccessStoryCarouselItem) => (
    <StoryCarouselItem key={el.id} data={el} />
  ));

  const showNeighbours = window.innerWidth > 1200 ? 40 : 0;

  return (
    <OwlCarousel
      className="amanmf__success-stories__carousel"
      dots={false}
      nav
      onChanged={carouselChangedHandler}
      navText={[
        `<Image className="nav-width sc-carousel-nextBtn" src=${images.rightArrow} />`,
        `<img class="nav-width sc-carousel-prevBtn" src=${images.leftArrow}>`,
      ]}
      rewind
      loop
      items={1}
      margin={20}
      stagePadding={showNeighbours}
      rtl={lang === "en" ? false : true}
    >
      {loading ? <Loader /> : content}
    </OwlCarousel>
  );
};

export default SuccessStoriesCarousel;
