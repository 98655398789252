import React, { useState, useRef, useEffect, useCallback } from "react";
import "../../../style/components/dropdown.scss";
import { Form } from "react-bootstrap";
import images from "assets/images";
// import { FaChevronDown, FaTimes } from "react-icons/fa";

interface DropdownSelectProps {
  options: any[];
  selectedValue: string | number | null;
  onChange: (value: number | null) => void;
  placeholder?: string;
  title: string;
  disabled?: boolean;
}

const BranchesDropDownSelect: React.FC<DropdownSelectProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = "اختر نوع النشاط",
  title,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find(
    (option) => option.branchId === selectedValue
  );

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const handleClick = useCallback(() => {
    if (disabled) return;
    setIsOpen(!isOpen);
  }, [disabled]);
  return (
    <div className="dropdown-select" ref={dropdownRef}>
      {/* Input Field */}
      <div
        className={`dropdown-header ${isOpen ? "open" : ""}`}
        onClick={handleClick}
        style={{
          pointerEvents: !disabled ? "auto" : "none",
          opacity: !disabled ? 1 : 0.5,
        }}
      >
        <img src={images.icon_map_pin} className="start-icon" />
        <span
          style={{
            fontSize: "13px",
            fontWeight: "300",
          }}
        >
          {selectedOption ? selectedOption.branchName : placeholder}
        </span>
        <span className="arrow-icon"></span>
        <div className="icons">
          {/* {selectedOption && (
            <FaTimes
              className="clear-icon"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            />
          )} */}
          {/* <FaChevronDown className="arrow-icon" /> */}
        </div>
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <div className="dropdown-list">
          {/* Search Input */}
          <input
            type="text"
            className="search-input"
            placeholder="بحث"
            disabled={disabled}
            style={{
              paddingRight: "30px",
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={images.search_icon} className="search-icon" alt="Search" />
          {/* List Items */}
          <div className="options">
            {options
              .filter((option) => option.branchName.includes(search))
              .map((option) => (
                <div
                  key={option.branchId}
                  className={`option ${
                    selectedValue === option.branchId ? "selected" : ""
                  }`}
                  onClick={() => {
                    onChange(option.branchId);
                    setIsOpen(false);
                  }}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      lineHeight: "21.6px",
                    }}
                  >
                    {option.branchName}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BranchesDropDownSelect;
