import React from "react";
import { useTranslation } from "react-i18next";

import DefaultMain from "layout";
import images from "assets/images";
import ContactusForm from "./components/ContactusForm";
import FinancialGrowShapeAnimated from "components/FinancialGrowShapeAnimated";
import { Helmet } from "react-helmet";

const ContactUs: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Contact Us - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        <section className="contact-us__container d-flex">
          <div className="contact-us__header">
            <img
              src={images.orangetrin}
              alt="orange-tri"
              className="contact-us__header__triangle"
            />
            <h2 className="font bold text-white pb-3">{t("Contact us")}</h2>
            <p className="text-white">
              {t(
                "Aman appreciates your valuable feedback, suggestions, or any inquiries"
              )}
            </p>
            <div className="d-flex contact-us__header__bottomSide">
              <h3 className="font light text-white me-3">19910</h3>
              <img
                src={images.contactusPhone}
                alt="phone"
                className="contact-us__header__phone"
              />
            </div>
          </div>
          <div className="contact-us__form bg-white">
            <ContactusForm />
            <div className="animated-shap-container">
              <FinancialGrowShapeAnimated />
            </div>
          </div>
          <p className="text-white mx-3 animated-para">
            {t("Please leave your data and we will reply as soon as possible")}
          </p>
        </section>
      </DefaultMain>
    </>
  );
};
export default ContactUs;
