import React, { useRef } from "react";
import ReactOwlCarousel from "react-owl-carousel-rtl";

import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as PrevBtn } from "assets/images/prev-arrowBtn.svg";
import { ReactComponent as NextBtn } from "assets/images/next-arrowBtn.svg";
import { ReactComponent as AboutVerticalLineEn } from "assets/images/aboutVerticalLineEn.svg";
import { ReactComponent as AboutVerticalLineAr } from "assets/images/aboutVerticalLineAr.svg";
import AboutCarouselItem from "./components/AboutCarouselItem";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { HistoryChartType } from "types";
import { Spinner } from "react-bootstrap";

interface IProps {
  loading: boolean;
}

const Section4: React.FC<IProps> = ({ loading }) => {
  const { t } = useTranslation();
  const carouselRef = useRef<ReactOwlCarousel>(null);
  const carouselSvgRef = useRef<SVGSVGElement>(null);
  const textBoxRef = useRef<HTMLParagraphElement>(null);
  const { about } = useSelector((state: RootState) => state.contentReducer);
  const { lang } = useSelector((state: RootState) => state.settingsReducer);

  // const [description, setDescription] = useState('');

  const getActiveIndex = (owlItemsLen: number, currVal: number) => {
    let index = isNaN(currVal) ? 4 : currVal;
    if (index >= owlItemsLen) {
      index = 0;
    }
    return index;
  };

  const updateTextContent = (year: string) => {
    if (about.history_charts.length) {
      const activeItem = about.history_charts.find(
        (el: HistoryChartType) => el.year == year
      );
      textBoxRef!.current!.textContent = activeItem?.description;
    }
  };

  const updateSvgContent = (year: string) => {
    if (!carouselSvgRef.current) return;
    const svgContent = carouselSvgRef!.current!.querySelector("tspan");
    svgContent!.textContent = year;
  };

  const updateActiveItem = (prevActive: any, currActive: any) => {
    prevActive.forEach((el: any) => el.classList.remove("active"));
    currActive?.children[0].firstChild.classList.add("active");
    const activeYear = currActive.querySelector("h4").textContent;
    updateSvgContent(activeYear);
    updateTextContent(activeYear);
  };

  const carouselChangeHandler = (ev: any) => {
    if (about.history_charts?.length) {
      const carouselItems = ev.currentTarget.querySelectorAll(".owl-item");
      const prevActive = ev.currentTarget.querySelectorAll(
        ".text-content.active"
      );
      let activeIndex = getActiveIndex(carouselItems.length, ev.property.value);
      let currActive = carouselItems[activeIndex];
      updateActiveItem(prevActive, currActive);
    }
  };

  const carouselClickHandler = (ev: any) => {
    if (ev.currentTarget.value === "next") {
      carouselRef.current!.next(250);
    } else {
      carouselRef.current!.prev(250);
    }
  };

  let nitems = 4;
  if (window.innerWidth <= 1690) nitems = 3;
  if (window.innerWidth <= 1023) nitems = 2;
  if (window.innerWidth <= 767) nitems = 1;

  return (
    <section className="aboutAman__history__section">
      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        about?.history_charts?.length && (
          <div className="d-flex aboutAman__history__slider">
            <div className="font light col-3 aboutAman__history__yearDescription">
              <p className="text-white font light" ref={textBoxRef}></p>
            </div>
            {window.innerWidth > 1366 &&
              (lang == "ar" ? (
                <AboutVerticalLineAr ref={carouselSvgRef} />
              ) : (
                <AboutVerticalLineEn ref={carouselSvgRef} />
              ))}
            <button
              className="carousel-nav --prev"
              onClick={carouselClickHandler}
              value="prev"
            >
              <PrevBtn />
            </button>
            <OwlCarousel
              className="owl-theme history-carousel"
              margin={20}
              items={nitems}
              loop
              rtl
              onChange={carouselChangeHandler}
              ref={carouselRef}
            >
              {about.history_charts?.map((el: HistoryChartType) => (
                <AboutCarouselItem
                  key={el.id}
                  year={el.year}
                  title={el.title}
                />
              ))}
            </OwlCarousel>
            <button
              className="carousel-nav --next"
              onClick={carouselClickHandler}
              value="next"
            >
              <NextBtn />
            </button>
          </div>
        )
      )}
    </section>
  );
};

export default Section4;
