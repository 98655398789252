import { t } from "i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { SyntheticEvent } from "react";

import { NewsItem } from "types";
import { RootState } from "store/configStore";

const NewsDetailsFooter: React.FC = (props) => {
  const navigate = useNavigate();
  const { newsDetails } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const handleReadMore = (event: SyntheticEvent, article: NewsItem) => {
    if (article.external_link) {
      window.location.replace(article.external_link);
    } else {
      navigate(`/news/${article.id}`);
    }
  };

  return (
    <section className="article-footer-container">
      <h3 className="font bold">{t("Also read")}</h3>
      <div className="article-footer">
        {newsDetails?.related_news?.map((article: NewsItem, index: number) =>
          index < 4 ? (
            <div className="article-footer__div" key={article.id}>
              <p>{article.header}</p>
              <button
                onClick={(event) => handleReadMore(event, article)}
                className={"amnmf-btn-outlined mx-0"}
              >
                {t("read more")}
              </button>
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </section>
  );
};
export default NewsDetailsFooter;
