import axios, { AxiosResponse } from "axios";
import endPoints from "netwrok/endPoints";
import { RootState } from "store/configStore";
import {
  GeneralLoanType,
  LoanReqData,
  VseLoanReqData,
  LoanReqOnlineData,
  StepOneOcrData,
  LoanReqMFData,
} from "types/backend.modal";
import {
  setAmountOfLoansMf,
  setAmountOfLoansSme,
  setAmountOfLoansLv,
} from "./contentActions";

export const API_postOnGroundLoanReqMf = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);
      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("category", data.category);
      form_data.append("activity_type", data.activity_type);
      form_data.append(
        "have_existing_activity",
        String(data.have_existing_activity)
      );
      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      console.log("API_postOnGroundLoanReqMf ===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnGroundLoanReqMf ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnGroundLoanReqSme = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);

      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      //form_data.append("typeOfProduct", String(data.productType));
      //form_data.append("productSelected", String(data.productSelected));
      form_data.append("category", data.category);
      form_data.append(
        "have_official_document",
        String(data.have_official_document)
      );
      form_data.append(
        "sales_over_one_million",
        String(data.sales_over_one_million)
      );

      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      // console.log("API_postOnGroundLoanReqSme ===>>>", res.data);
      return res;
    } catch (error: any) {
      // console.error("API_postOnGroundLoanReqSme ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnGroundLoanReqVse = (data: VseLoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      //form_data.append("amount_of_loan", data.amount_of_loan as any);
      //form_data.append("product_id", data.amount_of_loan as any);

      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("product_type_value", String(data.product_type));
      form_data.append("product", String(data.product));
      //form_data.append("category", data.category);
      form_data.append(
        "have_official_document",
        String(data.have_official_document)
      );
      //form_data.append("sales_over_one_million", String(data.sales_over_one_million));

      const res = await axios.post(
        endPoints.application.onVseLoanReq,
        form_data,
        config
      );
      // console.log("API_postOnGroundLoanReqSme ===>>>", res.data);
      return res;
    } catch (error: any) {
      // console.error("API_postOnGroundLoanReqSme ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnGroundLoanReqLv = (data: LoanReqData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("full_name", data.full_name);
      form_data.append("phone_number", data.phone_number);
      form_data.append("amount_of_loan", data.amount_of_loan as any);
      form_data.append("product_id", data.amount_of_loan as any);
      form_data.append(
        "transportation_product_type_id",
        data.transport_poduct_type_id as any
      );
      form_data.append("branch", String(data.branch));
      form_data.append("government", String(data.government));
      form_data.append("category", data.category);

      const res = await axios.post(
        endPoints.application.onGroundLoanReq,
        form_data,
        config
      );
      console.log("API_postOnGroundLoanReqLv ===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnGroundLoanReqLv ====>>>", error);
      return error.response;
    }
  };
};
export const API_postOnLoanRequestMF = (data: LoanReqMFData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("name", data.full_name);
      form_data.append("mobile", data.phone_number);
      form_data.append("amount", data.amount_of_loan as any);
      form_data.append("national_id", data.national_id as any);
      form_data.append("product_id", data.product_id as any);
      form_data.append("branch_id", String(data.branch));
      form_data.append("government_id", String(data.government));
      form_data.append("government_name", data.government_name);
      form_data.append("activity_id", "1");
      form_data.append("term_period", "12");
      form_data.append("id_card_back", String(data.id_card_back));
      form_data.append("id_card_front", String(data.id_card_front));
      form_data.append("utility_bills", String(data.id_card_front));
      form_data.append("voucher_code", String(data.voucher_code));
      form_data.append("promo_id", String(data.promo_id));
      form_data.append("islamic_loan_type", "1");
      form_data.append("source_id", "1");
      form_data.append("transportationProductType", "1");
      // let configData = {
      //   name: data.full_name,
      //   mobile: data.phone_number,
      //   amount: data.amount_of_loan as any,
      //   national_id: data.national_id as any,
      //   product_id: data.amount_of_loan as number,
      //   branch_id: data.branch,
      //   government_id: data.government,
      //   government_name: "cairo",
      //   activity_id: data.activity_type,
      //   term_period: 12,
      //   id_card_back: String(data.id_card_back),
      //   id_card_front: String(data.id_card_front),
      //   utility_bills: String(data.id_card_front),
      //   voucher_code: String(data.voucher_code),
      //   promo_id: data.promo_id,
      //   islamic_loan_type: 1,
      // };
      const res = await axios.post(
        endPoints.application.onGroundLoanReqMF,
        form_data,
        config
      );
      console.log("API_postOnGroundLoanReqMF===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnGroundLoanReqLv ====>>>", error);
      return error.response;
    }
  };
};

export const API_postOnlineLoanReq = (data: LoanReqOnlineData) => {
  return async (_: React.Dispatch<any>, getState: () => RootState) => {
    const config = {
      headers: {
        "Accept-Language": getState().settingsReducer.lang,
      },
    };
    try {
      let loan_form_data = new FormData();
      loan_form_data.append("full_name", data.full_name);
      loan_form_data.append("phone_number", data.phone_number);
      loan_form_data.append("address", data.address);
      loan_form_data.append("purpose_of_loan", data.purpose_of_loan);
      loan_form_data.append("government", String(data.government));
      loan_form_data.append("branch", String(data.branch));
      const res = await axios.post(
        endPoints.application.onlineLoanReq,
        loan_form_data,
        config
      );
      console.log("API_postOnlineLoanReq ====>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_postOnlineLoanReq ====>>>", error);
      return error.response;
    }
  };
};

export const API_getAmountOfLoansMf = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansMf, config);
      dispatch(setAmountOfLoansMf(res.data));
      console.log("API_getAmountOfLoansMf ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansMf ====>>>", error);
    }
  };
};
export const API_StepOneOCR = (data: StepOneOcrData) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `2UMrf9W3aSI7-nFfKPOyCTWILThUK8_ScvMGLmKwl0QaVtMpZFu_K0ZGj8pFAXzNCTfTeX5r8BqG06xN4VYjKXrKB8iG6Pim3aXtf2EAWLOCox6_0agi8GeEgE-35DMCvk_Eg401zYQKdiZj7m9IHhdX7nuGxtOe5zP7ez-Sl39f5uigfr605evLD-kExh9krpp0ioBZFACQPY2jPJ-YTOQDIEdBsdaZJrR6ED6v_zCJbd0z-39twT7VeIv00Pmro_hld21CE87kGCKavm6juQ`,
        },
      };
      let API_data = JSON.stringify({
        FrontPhotoBasestring64: data.FrontPhotoBasestring64,
        BackPhotoBasestring64: data.BackPhotoBasestring64,
        EntityId: 1,
      });
      const res = await axios.post(endPoints.content.ocr, API_data, config);
      console.log("API_StepOneOCR ===>>>", res);
      return res;
    } catch (error: any) {
      console.error("API_StepOneOCR ====>>>", error);
      return error.response;
    }
  };
};
export const API_ValidatePromoCode = (code: string) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const res = await axios.post(endPoints.content.promocode + code);
      console.log("API_ValidatePromoCode ===>>>", res);
      return res;
    } catch (error: any) {
      return error.response;
    }
  };
};
export const API_GetLeadMarket = (nationalId: string) => {
  return async () => {
    try {
      const url = `https://mf.amanmicrofinance.com/api/applications/get-lead-requests_national-id/${nationalId}`;
      console.log(url);
      const res = await axios.post(url);
      console.log("API_GetLeadMarket ===>>>", res.data);
      return res.data;
    } catch (error: any) {
      return error.response;
    }
  };
};
export const API_getAmountOfLoansSme = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansSme, config);
      dispatch(setAmountOfLoansSme(res.data));
      console.log("API_getAmountOfLoansSme ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansSme ====>>>", error);
    }
  };
};
export const API_getAmountOfLoansLv = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = await axios.get(endPoints.content.amountOfLoansLv, config);
      dispatch(setAmountOfLoansLv(res.data));
      console.log("API_getAmountOfLoansLv ===>>>", res.data);
      // return res
    } catch (error) {
      console.error("API_getAmountOfLoansLv ====>>>", error);
    }
  };
};

export const API_getPhoneNumerLoanStatus = (
  phoneNumber: string,
  loanType: GeneralLoanType
) => {
  return async (_: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const config = {
        headers: {
          "Accept-Language": getState().settingsReducer.lang,
        },
      };
      const res = (await axios.get(
        endPoints.application.checkPhoneNumberGeneral +
          phoneNumber +
          `?type=${loanType}`,
        config
      )) as unknown as AxiosResponse<any[]>;
      // console.log("API_postPhoneNumerToCheck ===>>>", res);
      return res.data.length == 0 ? true : false;
    } catch (error) {
      // console.error("API_postPhoneNumerToCheck ====>>>", error);
    }
  };
};
