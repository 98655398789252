import React, { useState, useRef, useEffect } from "react";
import "../../../style/components/dropdown.scss";
import { Form } from "react-bootstrap";
import images from "assets/images";
// import { FaChevronDown, FaTimes } from "react-icons/fa";

interface DropdownSelectProps {
  options: any[];
  selectedValue: string | number | null;
  onChange: (value: number | null) => void;
  placeholder?: string;
  title: string;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = "اختر نوع النشاط",
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find(
    (option) => option.productId === selectedValue
  );

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="mb-3 row-container">
      <Form.Label className="font bold">
        {title}
        <span className="text-danger"> * </span>
      </Form.Label>
      <div className="dropdown-select" ref={dropdownRef}>
        {/* Input Field */}
        <div
          className={`dropdown-header ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={images.icon_activity_type} className="start-icon" />
          <span
            style={{
              fontSize: "13px",
              fontWeight: "300",
            }}
          >
            {selectedOption ? selectedOption.productName : placeholder}
          </span>
          <span className="arrow-icon"></span>
          <div className="icons">
            {/* {selectedOption && (
            <FaTimes
              className="clear-icon"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            />
          )} */}
            {/* <FaChevronDown className="arrow-icon" /> */}
          </div>
        </div>

        {/* Dropdown List */}
        {isOpen && (
          <div className="dropdown-list">
            {/* Search Input */}
            <input
              type="text"
              className="search-input"
              placeholder="بحث"
              style={{
                paddingRight: "30px",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              src={images.search_icon}
              className="search-icon"
              alt="Search"
            />
            {/* List Items */}
            <div className="options">
              {options
                .filter((option) => option.productName.includes(search))
                .map((option) => (
                  <div
                    key={option.productId}
                    className={`option ${
                      selectedValue === option.productId ? "selected" : ""
                    }`}
                    onClick={() => {
                      onChange(option.productId);
                      setIsOpen(false);
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        lineHeight: "21.6px",
                      }}
                    >
                      {option.productName}
                    </span>

                    {/* SCSS-based arrow */}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownSelect;
