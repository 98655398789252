import React from "react";

import { useTranslation } from "react-i18next";
import { ReactComponent as DottedLine } from "../../../../assets/images/dottedLine.svg";

const AboutCarouselItem: React.FC<{
  year: string;
  title: string;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="item d-flex justify-content-center align-items-center">
      <div className="text-content">
        <h4 className="font bold text-center">{props.year}</h4>
        <p className="font bold text-center">{t(props.title)}</p>
      </div>
      <span>
        <DottedLine />
      </span>
    </div>
  );
};

export default AboutCarouselItem;
