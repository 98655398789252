import React from "react";
import { Row, Col } from "react-bootstrap";
import ServiceRow from "../components/ServiceRow";
import { useTranslation } from "react-i18next";

interface ListItem {
  txt: string;
  img: string;
}
interface ServiceItem {
  title: string;
  details: string;
  itemsList: ListItem[];
}

const SectionRow: React.FC<{ className: string; data: ServiceItem }> = ({
  className,
  data,
}) => {
  const { t } = useTranslation();
  const content2 = data.itemsList.map(
    (el: ListItem, i: React.Key | null | undefined) => (
      <ServiceRow key={i} title={el.txt} icon={el.img} />
    )
  );

  let formattedTitle: any = t(data.title);
  let titleSp = formattedTitle.split(" ");
  if (titleSp.length > 3) {
    formattedTitle = (
      <>
        {titleSp.slice(0, 2).join(" ")}
        <br />
        {titleSp.slice(2).join(" ")}
      </>
    );
  }

  return (
    <Row className="bright-blue">
      <Col className="right-space">
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <h2 className="font bold orangeBg">{formattedTitle}</h2>
        </div>
        <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
          <p className="font light">{t(data.details)}</p>
        </div>
      </Col>
      <Col className={`left-height seprator-line ${className}`}>{content2}</Col>
    </Row>
  );
};

export default SectionRow;
