import React from "react";
import { Form, FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface props {
  content: string;
  inputValue: string;
  changeHandler: (e: any) => void;
}
const FormCheckInput: React.FC<props> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormCheck.Label className="mx-2">{t(props.content)}</FormCheck.Label>
      <Form.Check
        type="radio"
        name="activityRadio"
        value={props.content}
        id={props.content}
        checked={props.inputValue === props.content}
        onChange={props.changeHandler}
      />
    </>
  );
};
export default FormCheckInput;
