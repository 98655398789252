import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images";
import StoryModal from "../../../components/StoryModal";
import { SuccessStoryCarouselItem } from "../../../types/index";

const StoryCarouselItem: React.FC<{ data: SuccessStoryCarouselItem }> = ({
  data,
}) => {
  const { t } = useTranslation();
  return (
    <div className="story-box">
      <Image src={images.visualArrow} alt="" className="floating-arrow" />
      <Image src={data.image} alt="sc slider" className="customer-img" />
      <p className="f-md font bold title">{data.description}</p>
      <div className="story-details">
        <h3 className="f-sm font bold">{t("Aman clients")}</h3>
        <h4 className="f-md font bold mb-5">{data.description}</h4>
        <p className=" font light">{data.description}</p>
        <StoryModal
          videoUrl={data.url}
          className="amnmf-btn-outlined"
          name={data.description}
        >
          {t("Show More")}
        </StoryModal>
      </div>
    </div>
  );
};

export default StoryCarouselItem;
