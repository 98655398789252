import images from "assets/images";
import DefaultMain from "layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OnlineMerchantForm from "./components/OnlineMerchantForm";
import { Helmet } from "react-helmet";

const LoanRequestOnline: React.FC = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(true);
  const showOnlineMerchantForm = () => {
    setShowForm(false);
    const element = document.querySelector<HTMLElement>(
      ".online__merchant__content"
    );
    element!.style.marginTop = "0rem";
  };

  return (
    <>
      <Helmet>
        <title>Online Merchant - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <section className="loan__merchant__section py-5 d-flex flex-wrap">
          <div className="col-lg-5 col-xl-6 online__merchant__content">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={images.onlineMerchants}
                alt="Online Merchant"
                className="online__merchant__img"
              />
            </div>
            <div className="online__merchant__header">
              <h1 className="text-white font bold">
                {t("Online Merchant Finance")}
              </h1>
              <p className="text-white font bold">
                {t(
                  "Online merchant financing product designed to support your e-commerce with minimal conditions and procedures"
                )}
              </p>
            </div>
            <div className="online__merchant__images d-flex py-5">
              <div className="col d-flex align-items-center">
                <img src={images.moneyIcon} alt="Money" className="w-25" />
                <div className="mx-4">
                  <p className="font bold text-white mb-0">
                    {t("Maximum Finance Amount")}
                  </p>
                  <p className="font bold text-white">{t("200,000 EGP")}</p>
                </div>
              </div>
              <div className="col d-flex align-items-center">
                <img src={images.timeIcon} alt="Time" className="w-25" />
                <div className="mx-4">
                  <p className="font bold text-white mb-0">
                    {t("Financing Term")}
                  </p>
                  <p className="font bold text-white">
                    {t("1 month to 2 years")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {showForm ? (
            <div className="col-lg-7 col-xl-6 bg-white online__merchant__content__conditions">
              <img
                src={images.artArrow}
                alt="Art Arrow"
                className="art__arrow__img"
              />
              <div className="online__merchant__content__conditions__card">
                <div className="online__merchant__content__header">
                  <h4 className="font bold">
                    {t(
                      "Do you own an online store on Facebook - Instagram - Souq - Jumia - Noon and are you looking for financing to expand your business?"
                    )}
                  </h4>
                  <h5 className="font bold pt-5 pb-4">
                    {t(
                      "Get started today with online merchant financing provided by Aman"
                    )}
                  </h5>
                </div>
                <div className="online__merchant__conditions pb-2">
                  <div className="d-flex align-items-center online__merchant__conditions__header pb-3">
                    <img src={images.conditionIcon} alt="Condition" />
                    <h6 className="font bold mx-3">
                      {t("Conditions to be met by the client")}
                    </h6>
                  </div>
                  <ul className="online__merchant__conditions__list">
                    <li className="d-flex align-items-center pb-4">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t(
                          "To be of Egyptian nationality and residing within the governorate in which the client, the applicant for financing, resides and works in."
                        )}
                      </span>
                    </li>
                    <li className="d-flex align-items-center pb-4">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t(
                          "Age ranges from 21 to 63 years when funding is granted."
                        )}
                      </span>
                    </li>
                    <li className="d-flex align-items-center pb-4">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t(
                          "To have a good reputation through the credit and field inquiries prepared for them for each of the place of residence and the place of work or the place of practice of the activity."
                        )}
                      </span>
                    </li>
                    <li className="d-flex align-items-center pb-4">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t("To have a valid national ID card.")}
                      </span>
                    </li>
                    <li className="d-flex align-items-center pb-4">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t(
                          "Not to be a current client or guarantor of the company."
                        )}
                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <img src={images.checkMark} alt="" />
                      <span className="font">
                        {t(
                          "That he has been practicing the activity for a year."
                        )}
                      </span>
                    </li>
                  </ul>
                  <button
                    className="btn rounded-pill my-5 d-block m-auto w-100 p-3"
                    onClick={showOnlineMerchantForm}
                  >
                    <span className="text-white font bold mx-2">
                      {t("Start a funding request")}
                    </span>{" "}
                    <img src={images.leftArrowSmall} alt="Left Arrow" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <OnlineMerchantForm />
          )}
        </section>
      </DefaultMain>
    </>
  );
};
export default LoanRequestOnline;
