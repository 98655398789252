const EditIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5766 6.97424L17.3869 2.78549C17.0353 2.43398 16.5584 2.23651 16.0613 2.23651C15.5641 2.23651 15.0872 2.43398 14.7356 2.78549L3.17438 14.3458C2.99968 14.5194 2.86116 14.726 2.76686 14.9536C2.67255 15.1811 2.62434 15.4251 2.62501 15.6714V19.8611C2.62501 20.3584 2.82255 20.8353 3.17418 21.1869C3.52581 21.5386 4.00273 21.7361 4.50001 21.7361H8.68969C8.93602 21.7368 9.18003 21.6886 9.40759 21.5943C9.63514 21.5 9.84172 21.3615 10.0153 21.1867L17.4647 13.7383L17.8547 15.0405L14.58 18.3152C14.3687 18.5265 14.2499 18.8132 14.2499 19.112C14.2499 19.4109 14.3687 19.6976 14.58 19.9089C14.7914 20.1203 15.078 20.239 15.3769 20.239C15.6758 20.239 15.9624 20.1203 16.1738 19.9089L19.9238 16.1589C20.0674 16.0151 20.1693 15.8351 20.2189 15.6381C20.2684 15.441 20.2636 15.2341 20.205 15.0395L19.2731 11.9336L21.5784 9.6283C21.7528 9.45402 21.8911 9.24707 21.9853 9.0193C22.0796 8.79152 22.1281 8.54739 22.1279 8.30088C22.1277 8.05436 22.0789 7.8103 21.9843 7.58266C21.8897 7.35502 21.7512 7.14827 21.5766 6.97424ZM6.09376 14.6111L12.75 7.95486L16.4063 11.6111L9.75001 18.2674L6.09376 14.6111ZM4.87501 16.5799L7.78126 19.4861H4.87501V16.5799ZM18 10.0174L14.3438 6.36111L16.0631 4.64174L19.7194 8.29799L18 10.0174Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
