import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import NavBar from "./components/navBar";

const DefaultMain: React.FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <main>
      <NavBar />
      {props.children}
      {location.pathname !== "/loan-request-mf" && (
        <NavLink to={"/loan-request-mf"} className="apply-for-loan-btn">
          <p className="font bold">{t("Apply for loan")}</p>
        </NavLink>
      )}
      <Footer />
    </main>
  );
};

export default DefaultMain;
