import images from "assets/images";
import { on } from "events";
import React, { FC } from "react";
import Lottie from "lottie-react";
import SentAnimation from "assets/animations/Email-Sent.json";
interface Props {
  setIsSubModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMainModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const Success: FC<Props> = ({
  setIsSubModalVisible,
  setIsMainModalVisible,
  setStep,
}) => {
  const onClick = () => {
    setIsSubModalVisible(false);
    setIsMainModalVisible(false);
    setStep(1);
  };
  return (
    <div className="success-modal-container">
      <Lottie
        animationData={SentAnimation}
        loop={true}
        width={177}
        height={177}
        // style={{ width: "200px", height: "200px" }}
      />
      <p>تم إرسال طلبك و الأن هو قيد المراجعة و سنقوم بمراسلتك خلال 48 ساعة</p>
      <div
        className="form-modal-footer"
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingInlineEnd: "none",
          paddingInline: "2rem",
        }}
      >
        <button
          className="form-modal-close-button"
          onClick={() => onClick()}
          disabled={false}
        >
          القائمة الرئيسيه
        </button>
      </div>
    </div>
  );
};

export default Success;
