import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image, InputGroup, FormControl, Spinner } from "react-bootstrap";

import images from "../../../assets/images";
import ContentBox from "../components/ContentBox";
import axios from "axios";
import { baseUrl } from "netwrok/baseUrl";
import endPoints from "netwrok/endPoints";
import PayInstallment from "./PayInstallment";

const steps = [
  "Use the phone number registered with Aman",
  "You will receive a confirmation message that must be written",
  "After verification, you will be able to review your latest installment",
];

const InstallmentsStart: React.FC = () => {
  const { t } = useTranslation();
  // LOCAL STATE
  const [nationalId, setNationalId] = useState("");
  const [step, setStep] = useState(1);
  const [installment, setInstallment] = useState({});
  const [vodafoneUrl, setVodafoneUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // METHODS
  const onNationalIdChange = (e: any) => {
    console.log("CHANGE INPUT: ", e.target.value);
    setErrorMessage('');
    setNationalId(e.target.value);
  };
  const getVodafoneIframe = (data: any) => {
    const { redirect_url } = data;
    setVodafoneUrl(redirect_url);
    // setStep(step + 1);
    window.open(redirect_url)
  };
  const API_getInstallment = () => {
    setLoading(true);
    axios({
      url: `https://mf.marketing-amansuperapp.online/api${endPoints.vodafone.getInstallment}`,
      method: "POST",
      data: {
        national_id: nationalId,
      },
    }).then(
      (res) => {
        console.log("GET USER INSTALLMENT SUCCESS: ", res);
        setLoading(false);
        if (res.data.data.details) {
          setInstallment(res.data.data.details[0]);
          setStep(step + 1);
        } else {
          setErrorMessage(res.data.message)
        }
      },
      (err) => {
        console.log("GET INSTALLMENT FAILED:", err);
        setLoading(false);
        setErrorMessage(err.response.data.message)

      }
    );
  };

  const boxesContent = steps.map((title: string, index: number) => <ContentBox key={index} index={index + 1} title={title} />);
  if (step === 1) {
    return (
      <>
        <div className="content-boxes">{boxesContent}</div>
        <div className="user-phone">
          <InputGroup className="mb-3 user-phone_input">
            {/* <Image src={images.phoneIcon} className="phone-icon" alt="" /> */}
            <FormControl
              placeholder={t("National Id")}
              value={nationalId}
              aria-label="national id"
              aria-describedby="basic-addon1"
              onChange={onNationalIdChange}
            />
            <button onClick={API_getInstallment} className="send-btn">
              {loading ? <Spinner animation="border" size="sm" /> : <p>{t("Continue")}</p>}
              <Image src={images.arrow} />
            </button>
          </InputGroup>
        </div>
       {errorMessage && <div className="error-message">
          <p>{errorMessage}</p>
        </div>}
      </>
    );
  } else if (step === 2) {
    return <PayInstallment installment={installment} national={nationalId} getVodafoneIframe={getVodafoneIframe} />;
  }
  // } else if (step === 3) {
  //   return <iframe src={vodafoneUrl} width="100%" height="100%"></iframe>;
  // }
  else {
    return <div></div>;
  }
};

export default InstallmentsStart;
