import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DefaultMain from "layout";
import images from "assets/images";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/configStore";
import axios from "axios";
import ReactLoading from "react-loading";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { API_getPhoneNumerLoanStatus } from "store/actions/loanReqActions";
import CommonOrangeButton from "components/Button";


const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const islamicLoansType = [
  {
    id: 1,
    type: "مشاركة",
  },
  {
    id: 2,
    type: "مرابحة بضائع",
  },
  {
    id: 3,
    type: "مرابحة معدات و ألالات",
  },
  {
    id: 4,
    type: "مرابحة وسائل نقل",
  },
  {
    id: 5,
    type: "مرابحة مستلزمات طبية",
  },
];

const IslamicLoan: React.FC = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [islamicOption, setIslamicOption] = useState(0);
  const [fullName, setFullName] = useState("");
  const [isFullNameValid, setIsFullNameValid] = useState<boolean | undefined>(
    undefined
  );
  const [checkBoxOption, setCheckBoxOption] = useState(0);
  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const [govermentsList, setGovermentsList] = useState([]);
  const [productType, setProductType] = useState({ id: "-1", name: "" });
  const [city, setCity] = useState("0");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<
    boolean | undefined
  >(undefined);
  const [openForm, setOpenForm] = useState(false);
  const [typesOfMontag, setTypesOfMontag] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [branch, setBranch] = useState("0");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ sent: false, error: false });
  const [amountOfLoans, setAmountOfLoans] = useState([]);
  const [selectedAmountOfLoanIslamic, setSelectedAmountOfLoanIslamic] =
    useState("0");
  const [isSendingPhoneNumber, setIsSendingPhoneNumber] = useState(false);
  const [isNumberAlreadyApplied, setIsNumberAlreadyApplied] = useState(false);
  const [isCheckBtnShown, setIsCheckBtnShown] = useState(true);
  const [isSubmitShown, setIsSubmitShown] = useState(false);

  useEffect(() => {
    if (fullName.length > 0)
      setIsFullNameValid(
        /^[\u0621-\u064A| +]+$/.test(fullName) ||
        /^[a-zA-Z| +]+$/.test(fullName)
      );
    else setIsFullNameValid(undefined);
  }, [fullName]);

  useEffect(() => {
    if (phoneNumber.length > 0)
      setIsPhoneNumberValid(EgyptionNumbersRegex.test(phoneNumber));
    else setIsPhoneNumberValid(undefined);
    setIsNumberAlreadyApplied(false);
    setIsCheckBtnShown((val: boolean) => {
      if (!val) return true;
      else return val;
    });
    setIsSubmitShown((val: boolean) => {
      if (val) return false;
      else return val;
    });

  }, [phoneNumber]);


  useEffect(() => {
    axios
      .get(
        "https://mf.amanmicrofinance.com/api/content/amount-of-loans?activityType=islamic"
      )
      .then((res) => {
        console.log("GET AMOUNT OF LOANS: ", res);
        setTypesOfMontag(res.data.Data);
      })
      .catch((err) => {
        console.log("GET AMOUNT OF LOANS FAILED: ", err);

       });

    axios
      .get(
        "https://mf.amanmicrofinance.com/api/company/governemnts?category=islamic"
      )
      .then((res) => {
        // console.log("res :", res.data.Data);
        setGovermentsList(res.data.Data);
      })
      .catch((err) => {
        // console.log("err : ", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://mf.amanmicrofinance.com/api/company/branches?governmentId=${city}&activityType=islamic`
      )
      .then((res) => {
        // console.log("res city :", res.data.Data);
        setBranchesList(res.data.Data);
      })
      .catch(() => { });
  }, [city]);

  const checkPhoneNumber = async (e: any) => {
    e.preventDefault();
    setIsSendingPhoneNumber(true);
    const isUserNumberAvaliable = (await dispatch(
      API_getPhoneNumerLoanStatus(phoneNumber, "islamic")
    )) as unknown as boolean;
    setIsSendingPhoneNumber(false);
    if (isUserNumberAvaliable) {
      setIsCheckBtnShown(false);
      setIsSubmitShown(true);
      setIsNumberAlreadyApplied(false);
    } else {
      setIsNumberAlreadyApplied(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Islamic Loan - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <section
          className="islamic-loan__container"
          style={{ justifyContent: "normal" }}
        >

          <div
            className="islamic-loan_details"
            style={{ justifyContent: "normal" }}
          >
            <img
              src={images.islamicLoanIcon}
            />
            <div>
              <h2 style={{ textAlign: "start" }}>

                برنامج التمويل الإسلامي
              </h2>
              <h4
                style={{
                  width: "",
                  textAlign: "start",
                }}
              >
                حريصون دائما على تقديم كافة الخدمات الي تلبي احتياجات جميع عملائنا
              </h4>
            </div>
          </div>

          {!success.sent && (
            <div className="islamic-loan_modal-box">
              {openForm === false && (
                <>
                  <div className="button-container">
                    <div
                      className="modal-buttons"
                      onClick={() => {
                        setIslamicOption(0);
                      }}
                      style={{
                        background:
                          islamicOption === 0 ? "#F37021" : "transparent",
                      }}
                    >
                      <h1> المشاركة الإسلامية</h1>
                    </div>

                    <div
                      className="modal-buttons"
                      onClick={() => {
                        setIslamicOption(1);
                      }}
                      style={{
                        background:
                          islamicOption === 1 ? "#F37021" : "transparent",
                      }}
                    >
                      <h1>المرابحة</h1>
                    </div>
                  </div>
                  <div className="islamic-loan__text-description">
                    {islamicOption === 0 ? (
                      <>
                        <p>
                          تطبيقاً لهذا النوع من البيوع أو المنتجات الإسلامية
                          تسعي الشركة بأن تقوم بمشاركة عملائها فى كافة العمليات
                          التجارية أو الصناعية أو الزراعية أو الخدمات ، وتدور
                          العلاقة بين الشركة وبين العميل فى إطار المشاركة بين
                          الطرفين وليس فى إطار علاقة الدائن والمدين ويتوقف عائد
                          المشاركات بالأموال على ما تسفر عنه نتيجة عملية
                          المشاركة بين الطرفين من ربح أو خسارة ،
                        </p>
                        <h6> عملية توزيع الأرباح</h6>
                        <p>
                          ويوزع عائد الأرباح بين الطرفين طبقا لما يتم عليه
                          الإتفاق عليه فى عقد المشاركة على الوجه التالى : نسبة
                          تُحدد من صافى الربح للعميل مقابل العمل والإدارة "
                          الشريك المدير" ، ويوزع ما يتبقى بين الطرفين بنسبة حصة
                          كل منهما فى رأس مال المشاركة.
                        </p>
                        <h6>طريقة التمويل </h6>
                        <p>
                          ويكون التمويل بالمشاركة بإن يطلب العميل تمويل مشروع
                          معين حيث تشارك الشركة العميل فى النتائج المتوقعة من
                          المشروع ( الأرباح أو الخسائر) ويتم عقد المشاركة وفقاً
                          لمجموعة من قواعد ومبادىء التوزيع التى يتفق عليها
                          الطرفان مسبقاً ووفقا لأحكام ومبادىء الشريعة الإسلامية
                          .
                        </p>
                        <h6> المشاركة المتناقصة </h6>
                        <p>
                          وتعد المشاركة المتناقصة هى أحد أشكال المشاركة التى
                          يكون فيها للعميل الحق بأن يحل محل الشركة تدريجيا ليصبح
                          المالك الوحيد للمشروع خلال مرحلة واحدة أو أكثر وفقاً
                          للشروط المتفق عليها وطبيعة المشروع ، وبوجه عام يصلح
                          تمويل المشاركة لجميع قطاعات الاقتصاد .
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          تُعرف المرابحة بأنها "عملية البيع على أساس السعر
                          الأصلي مضافًا إليه تكلفة الشراء والمصروفات والربح" أو
                          "عملية البيع على أساس رأس المال مضافًا إليه الربح
                          المحدد"
                        </p>

                        <h6>طريقة التمويل </h6>
                        <p>
                          في المرابحة تقوم الشركة بامتلاك السلع التي يحددها
                          العميل، بما في ذلك أصول الإنتاج وفقا للمواصفات التي
                          يحددها العميل. وبعد إمتلاك الشركة لهذه السلع يبيعها
                          للعميل بثمن يشمل تكلفة الشراء مضافًا إليها الربح
                          المحدد مقابل الجهود المبذولة لإتمام عملية الشراء
                          والنفقات التي تتحملها الشركة، ثم يتم تسليم السلع إلى
                          العميل مع المواصفات المطلوبة ويقوم العميل بدفع ثمن
                          السلع نقداً أو على أقساط دورية، وفقًا لعقد البيع.
                        </p>
                        <p>
                          كما تسعي الشركة أيضا لتوفير "البيع بالمرابحة" للشركات
                          من خلال توفير المواد الخام المحلية أو الأجنبية
                          والمعدات والآلات بهدف إنشاء وتوسيع خطوط الإنتاج .
                          وبوجه عام يصلح تمويل المرابحة الإسلامية لجميع قطاعات
                          الاقتصاد .
                        </p>

                        <div style={{ marginTop: 80 }} />
                      </>
                    )}

                    <button
                      onClick={() => {
                        setOpenForm(true);
                      }}
                      className="apply_loan_button"
                    >
                      ابدأ طلب التمويل
                    </button>
                  </div>
                </>
              )}

              {openForm && (
                <div className="apply-loan-modal">
                  <h2 className="apply-loan-title">طلب التمويل الإسلامي</h2>

                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label className="apply-loan-full-name">
                      {t("Full name")}
                      <h6 style={{ color: "#f37021" }}>*</h6>
                    </Form.Label>
                    <Form.Control
                      maxLength={100}
                      type="text"
                      placeholder={t("Full name") + "*"}
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                      required
                      className="form-input"
                    />
                    {isFullNameValid === false && (
                      <div className="error-state mb-3">
                        <img src={images.errorIcon} />
                        <p style={{ paddingTop: 0 }}>
                          {t("Please enter valid full name")}
                        </p>
                      </div>
                    )}
                  </Form.Group>

                  <div style={{ marginTop: 23 }} className="row-flex">
                    <div style={{ width: "50%" }}>
                      <Form.Label className="apply-loan-full-name">
                        نوع التمويل{" "}
                      </Form.Label>

                      <div className="row-plus-info-checkbox">
                        <div
                          className="check-box-plus-text"
                          onClick={() => {
                            setCheckBoxOption(0);
                          }}
                        >
                          <div
                            className="check-box"
                            style={{
                              borderColor:
                                checkBoxOption === 0 ? "#197989" : "#707070",
                            }}
                          >
                            {checkBoxOption === 0 && (
                              <div className="full-circle" />
                            )}
                          </div>

                          <h4
                            style={{
                              color:
                                checkBoxOption === 0 ? "#197989" : "#707070",
                            }}
                          >
                            مرابحة
                          </h4>
                        </div>

                        <div
                          className="check-box-plus-text"
                          onClick={() => {
                            setCheckBoxOption(1);
                          }}
                        >
                          <div
                            className="check-box"
                            style={{
                              borderColor:
                                checkBoxOption === 1 ? "#197989" : "#707070",
                            }}
                          >
                            {checkBoxOption === 1 && (
                              <div className="full-circle" />
                            )}
                          </div>

                          <h4
                            style={{
                              color:
                                checkBoxOption === 1 ? "#197989" : "#707070",
                            }}
                          >
                            مشاركة
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <Form.Label className="apply-loan-full-name">
                        نوع المنتج{" "}
                      </Form.Label>
                      <Form.Select
                        className="form-select-style"
                        value={productType.id}
                        onChange={(e: any) => {
                          setProductType({
                            id: e.target.value,
                            name: e.nativeEvent.target.text,
                          });
                          console.log("item", e.nativeEvent.target.text);
                        }}
                      >
                        <option value={"-1"}>نوع المنتج</option>

                        {islamicLoansType
                          .filter((value, index) => {
                            if (checkBoxOption === 1) {
                              return value.id === 1;
                            } else {
                              return value.id !== 1;
                            }
                          })
                          .map((montag: any) => (
                            <option
                              key={montag.id}
                              value={montag.id}
                              data-name={montag.type}
                              onSelect={() =>
                                setProductType({
                                  id: montag.id,
                                  name: montag.type,
                                })
                              }
                            >
                              {montag.type}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>

                  <Form.Group>
                    <Form.Label className="apply-loan-full-name">
                      {t("Amount of loan")}
                      <h6 style={{ color: "#f37021" }}>*</h6>
                    </Form.Label>
                    <Form.Select
                      className="form-select-style"
                      value={selectedAmountOfLoanIslamic}
                      onChange={(e: any) => {
                        setSelectedAmountOfLoanIslamic(e.target.value);
                      }}
                    >
                      <option value={"0"}>اختر مبلغ التمويل</option>
                      {typesOfMontag.map((montag: any) => (
                        <option key={montag.Id} value={montag.Id}>
                          {montag.Name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="formGridName"
                    style={{ marginTop: 20 }}
                  >
                    <Form.Label className="apply-loan-full-name">
                      المحافظة التي يوجد النشاط بها
                      <h6 style={{ color: "#f37021" }}>*</h6>
                    </Form.Label>

                    <Form.Select
                      className="form-select-style"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    >
                      <option value={"0"}>المحافظة</option>
                      {govermentsList.map((gov: any) => (
                        <option key={gov.Id} value={gov.Id}>
                          {gov.Name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label
                      className="apply-loan-full-name"
                      style={{ marginTop: 24 }}
                    >
                      اﻟﻔﺮﻉ ( الأقرب إلى محل العمل )
                      <h6 style={{ color: "#f37021" }}>*</h6>
                    </Form.Label>

                    <Form.Select
                      className="form-select-style"
                      value={branch}
                      onChange={(e) => {
                        //   console.log(e.target.value);
                        setBranch(e.target.value);
                      }}
                    >
                      <option value={"0"}>الفرع الأقرب</option>
                      {branchesList.map((gov: any) => (
                        <option key={gov.Id} value={gov.Id}>
                          {gov.Name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="formGridPhone"
                    style={{ marginTop: 20 }}
                  >
                    <Form.Label className="apply-loan-full-name">
                      {t("Telephone Number")}
                      <h6 style={{ color: "#f37021" }}>*</h6>
                    </Form.Label>
                    <Form.Control
                      maxLength={11}
                      type="phone"
                      value={phoneNumber}
                      className="form-input"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {isPhoneNumberValid === false && (
                                <div className="error-state">
                                  <img src={images.errorIcon} />
                                  <p>يجب أن يكون رقم الهاتف 11 رقمًا باللغة الإنجليزية</p>
                                  </div>

                    )}
                  </Form.Group>
                  {/* {isCheckBtnShown && (
                    <div className="d-flex align-items-center ">
                      <CommonOrangeButton
                        isSending={isSendingPhoneNumber}
                        disabled={!isPhoneNumberValid}
                        onClick={checkPhoneNumber}
                      >
                        {t("Check")}
                      </CommonOrangeButton>
                      <>
                        {isNumberAlreadyApplied && (
                          <div className="error-state">
                            <img src={images.errorIcon} />
                            <p>{t("This phone number is already registerd")}</p>
                          </div>
                        )}
                      </>
                    </div>
                  )} */}

                  {/* {isSubmitShown && ( */}
                    <button
                      className="send-form-button"
                      style={{
                        backgroundColor:
                          (loading ||
                            !(
                              fullName &&
                              city !== "0" &&
                              branch !== "0" &&
                              phoneNumber &&
                              isPhoneNumberValid &&
                              selectedAmountOfLoanIslamic !== "0" &&
                              productType.id !== "-1"
                            ))
                            ? "#bb9280"
                            : "#e1591f",
                      }}
                      disabled={
                        (loading ||
                          !(
                            fullName &&
                            city !== "0" &&
                            branch !== "0" &&
                            phoneNumber &&
                            isPhoneNumberValid &&
                            selectedAmountOfLoanIslamic !== "0" &&
                            productType.id !== "-1"
                          ))
                      }
                      onClick={() => {
                        setLoading(true);
                        let form_data = new FormData();
                        form_data.append("full_name", fullName);
                        // form_data.append("activity_type", "islamic");
                        form_data.append("category", "islamic");
                        form_data.append(
                          "product_id",
                          selectedAmountOfLoanIslamic
                        );
                        form_data.append("phone_number", phoneNumber);
                        form_data.append("islamic_loan_type", productType.name);
                        form_data.append("islamic_loan_type_id", productType.id);

                        form_data.append("government", city);
                        form_data.append("branch", branch);

                        axios
                          .post(
                            "https://mf.amanmicrofinance.com/api/applications/post-general-loan-request",
                            form_data,
                            {
                              headers: {
                                "Accept-Language": lang,
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then(
                            (res) => {
                              //   console.log("res from posting: ", res);
                              navigate('/loan/thanks')
                              setSuccess({ sent: true, error: false });
                              setLoading(false);
                            },
                            (err) => {
                              // console.log("err from posting: ", err);
                              setSuccess({ sent: true, error: true });

                              setLoading(false);
                            }
                          );
                      }}
                    >
                      {loading ? (
                        <ReactLoading
                          type="balls"
                          color={"#fff"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        "ابدأ طلب التمويل"
                      )}
                    </button>
                  {/* )} */}
                </div>
              )}

            </div>
          )}

          {success.sent && success.error && (
            <div className="islamic-loan_modal-box">
              <div className="apply-loan-modal">

                <div className="succes-form-icon-text">
                  <img
                    src={images.errorSVG}
                  />
                  <h3 style={{ color: '#000' }}>
                    {t("An Error Occured Please try again")}
                  </h3>

                </div>

                <button
                  onClick={() => {
                    if (success.error) {
                      setSuccess({ sent: false, error: false });
                    } else {
                      navigate("/");
                    }
                  }}
                  className="send-form-button"
                >
                  {success.error && "أعد المحاولة"}
                </button>
              </div>

            </div>
          )}
        </section>
      </DefaultMain>
    </>
  );
};
export default IslamicLoan;
