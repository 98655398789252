import React from "react";
import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import images from "../../../assets/images";

const NoInstallments: React.FC = (props) => {
  const { t } = useTranslation();

  const userNumber = "01112249433";

  return (
    <div className="no-installments">
      <Image src={images.noInstallment} alt="" />
      <h5 className="font bold">
        {t("There's no installment for this number")}
      </h5>
      <h6 className="font bold">{userNumber}</h6>
      <NavLink to={"../installments/start"} className="amnmf-btn --filled">
        {t("Back to Main")}
      </NavLink>
    </div>
  );
};

export default NoInstallments;
