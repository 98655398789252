import React from "react";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";

const MobileAppItem: React.FC<{ data: string }> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <li className="amanApp__advertise__checkList-item mb-3">
      <img src={images.checkmark} alt="Checkmark" />
      <span className="text-white font fs-5 d-inline-block mx-2">
        {t(data)}
      </span>
    </li>
  );
};
export default MobileAppItem;
