import images from "assets/images";
import React, { FC } from "react";
import Lottie from "lottie-react";
interface Props {
  setIsSubModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const ErrorContent: FC<Props> = ({ setIsSubModalVisible }) => {
  return (
    <div className="sorry-modal-container">
      {/*  <img src={images.sorryAnimation} alt="Sorry" /> */}
      <Lottie animationData={images.sorryAnimation} loop={true} />
      <h1>عذرًا</h1>
      <p>لقد حدث خطأ أثناء إرسال الطلب</p>
      <div
        className="form-modal-footer"
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingInlineEnd: "none",
          paddingInline: "2rem",
        }}
      >
        <button
          className="form-modal-close-button"
          onClick={() => setIsSubModalVisible(false)}
          disabled={false}
        >
          حسنا
        </button>
      </div>
    </div>
  );
};

export default ErrorContent;
