import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { loanRequestContent } from "content/loan_request";
import LoanRequestCarousel from "pages/loan_request-mf/components/LoanRequestCarousel";
import LoanRequestForm from "pages/loan_request-mf/components/LoanRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { GeneralLoanType } from "types/backend.modal";
import { RootState } from "store/configStore";
import { useLocation, useNavigate } from "react-router-dom";
import images from "assets/images";
import { Helmet } from "react-helmet";

interface Props {

}

const ThanksMessage: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const loc = useLocation();
    const { lang } = useSelector((state: RootState) => state.settingsReducer);
    const [success, setSuccess] = useState({ sent: false, error: false });
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        setSuccess({ sent: false, error: false });
    }, [loc.pathname]);




    
    return (
        <>
            <Helmet>

                <script key="function" id="facebook-pixel-script">
                   {`
                   !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1082423398941795');
                    fbq('track', 'PageView');
                    `}
                </script>
                <noscript key="image" id="facebook-pixel-image">
                    {`
                    <img height="1" width="1"
                    src="https://www.facebook.com/tr?id=1082423398941795&ev=PageView
                    &noscript=1"/>
                    `}
                </noscript>
            </Helmet>

            <DefaultMain>
                <section className="loan-request-section">
                    <Row>
                        <Container className="loan-request-carousel-container" fluid>
                            <h1 className="font bold carousel-header">
                                {t(loanRequestContent.carouselHeader)}
                            </h1>
                            <LoanRequestCarousel />
                        </Container>
                        <Col className="loan-request-form-container">


                            <div className="success-form">
                                <div className="succes-form-icon-text">
                                    <img
                                        src={images.successSVG}
                                    />
                                    <h3>تم تسجيل طلبك بنجاح</h3>
                                    <h3>عميلنا العزيز سيتم التواصل معك في أقرب وقت</h3>

                                </div>
                                <button
                                    onClick={() => {
                                        if (success.error) {
                                            setSuccess({ sent: false, error: false });
                                        } else {
                                            navigate("/");
                                        }
                                    }}
                                    className="send-form-button"
                                >
                                    الذهاب للصفحة الرئيسية
                                </button>

                                <h3>شكرا لثقتك بامان</h3>


                            </div>

                        </Col>
                    </Row>
                </section>
            </DefaultMain>
        </>
    );
};
export default ThanksMessage;
